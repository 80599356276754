import React, { useState } from "react";
import "./styles.css";
import Slider from "../Slider";
import { Stepper } from "../../components/Stepper";
import { useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import {
  ADMIN_BASE_URL,
  ADMIN_BACKOFFICE_BASE_URL,
} from "../../constant/constants";

const Verification = () => {
  const { loading, error, postData, fetchData } = useApi();
  const { clientId } = useParams();
  const [clientData, setClientData] = useState();

  async function getClientDetails() {
    const data = await fetchData(
      `admin-ekyc/user-data/${clientId}`,
      {},
      ADMIN_BASE_URL
    );
    setClientData(data);
  }

  React.useEffect(() => {
    getClientDetails().catch((err) => console.error(err));
  }, []);

  const handleApiCalls = async (url) => {
    try {
      const data = await fetchData(url, {}, ADMIN_BASE_URL);
      toast.success("E-sign initiated successfully");
    } catch (e) {
      toast.error(e.message ?? "Something went wrong");
    }
  };

  return (
    <main className="bg-secondary">
      <Loader open={loading} />
      <ToastContainer />
      <div className="h-[80vh] flex bg-light">
        <div className="bank-leftSide px-2 py-1 ">
          <Stepper clientData={clientData} />
        </div>
        <div className="bank-rightSide flex flex-col p-9">
          <Slider clientData={clientData} />
        </div>
      </div>
    </main>
  );
};

export default Verification;

