import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";
import useApi from "../../hooks/useApi";
import RButton from "../../components/rButton";
import "./styles.scss";
import Ekyc from "../../assets/kyc/ekycLogo.png";
import RImg from "../../components/rImg";
import EditButton from "../../assets/email/editButton.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

function Login(props) {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();

  useEffect(() => {
    AOS.init();
        // AOS.refresh(); 
  })
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);
    if (isValid) {
      postData(`otp/send?type=EMAIL`, { value: email });
      setErrorMsg("");
    } else {
      setErrorMsg("Invalid Email");
    }
    setValid(isValid);
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest({ email: email, otp: otp }, `otp/verify-email`);
      setErrorMsg("");
    } else {
      setErrorMsg("Invalid OTP");
    }
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
  };
  const handleEditEmail = () => {
    setValid(false);
  };
  return (
    <>
      <form className="email-container flex justify-around items-center flex-wrap rounded-t-2xl p-3 bg-grey">
        <div className="left-container">
          <div className="email-container-content text-white text-6xl max-[1087px]:text-5xl max-[998px]:text-4xl">
            <Loader open={loading} />
            <p>Open your RYZ </p>
            <p> Market Account in</p>
            <p>just 5 minutes</p>
          </div>
        </div>
        {/* Right Container */}
        <div className="right-container">
          <div className="rounded-xl text-white border-2 border-white">
            <div className="flex justify-end p-4">
              <p className="font-bold text-lightGrey text-3xl opacity-25">
                Login
              </p>
            </div>
            <div>
              {!valid && (
                <>
                  <div data-aos="fade-down" data-aos-duration="1500" className="login-container p-9 max-[998px]:p-5">
                    <div className="mb-3">
                      <p className="text-2xl">
                        Enter your <br />
                        Email Id
                      </p>
                    </div>
                    <div className="mb-5">
                      <input
                        autoFocus
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={email}
                        onChange={handleChange}
                        placeholder="Enter Your Email Id"
                        className="numberInput p-3 rounded-full spin-button-none text-white"
                      />
                    </div>
                    <div className="flex">
                      <input type="checkbox" checked className="me-4" />
                      <div className="text-sm max-[998px]:text-xs ">
                        I agree to receive communication from Ryz
                        <br /> through SMS, Whatsapp, Email, and Calls.
                      </div>
                    </div>
                    <br />
                    <RButton
                      handleButtonClick={handleSubmit}
                      buttonName="Send otp"
                      bgColor="bg-tertiary"
                      externalClassName="p-2"
                      type={"submit"}
                    />
                  </div>
                </>
              )}
              {valid ? (
                <>
                  <div data-aos="fade-down" data-aos-duration="1500" className="login-container p-9 max-[998px]:p-5">
                    <p className="right-content text-2xl mb-3">
                      Enter the OTP sent <br />
                      to your email id.
                    </p>
                    <div className="flex">
                      <p className="mb-3 opacity-70">{email}</p>
                      <div onClick={handleEditEmail}>
                        <RImg
                          innerImgClassName="w-[20px]"
                          externalClassName="ml-4"
                          src={EditButton}
                          alt="edit"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <input
                        autoFocus
                        type="number"
                        name="otp"
                        id="otp"
                        value={otp}
                        onChange={handleOtpChange}
                        placeholder="Enter otp"
                        className="numberInput p-3 rounded-full spin-button-none text-white"
                      />
                    </div>
                    <div className="mob-button flex justify-between mt-3">
                      <p onClick={handleCancelOTP} className="cursor-pointer">
                        Cancel OTP
                      </p>
                      <p onClick={handleSubmit} className="cursor-pointer">
                        Resend
                      </p>
                    </div>
                    <RButton
                      handleButtonClick={handleOtpSubmit}
                      buttonName="Continue"
                      bgColor="bg-tertiary"
                      externalClassName="p-2 mt-2"
                      type={"submit"}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </form>
      <div className="ekyc-container flex justify-end bg-lightBlack">
        <img className="ekyc-img w-[40%] mr-[9%]" src={Ekyc} alt="" />
      </div>
    </>
  );
}

export { Login };
