import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTE_SUPER_ADMIN } from "../../routes/routeConstant";
import SuperLogin from "../../userLogin";
import UserAdmin from "../../userAdmin";

function Super_Home() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTE_SUPER_ADMIN.login} element={<SuperLogin />} />
          <Route path={ROUTE_SUPER_ADMIN.admin} element={<UserAdmin />} />
          {/* Add other routes here */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Super_Home;
