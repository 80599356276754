import React, { useState } from "react"
import { STEPPER_TABS } from "../../constant/MapperConstant"
import { Address } from "../../layout/Address"
import Other from "../../layout/OtherInfo"
import Nominee from "../../layout/Nominee"
import "./styles.css"
import BasicDetails from "../../layout/BasicDetail"
import BankDetails from "../../layout/BankDetails"

const Stepper = ({clientData}) => {
  const [activeTab, setActiveTab] = useState(STEPPER_TABS[0])

  const handleOnClick = item => {
    setActiveTab(item)
  }

  const renderActiveComponent = () => {
    switch (activeTab.id) {
      case 1:
        return <BasicDetails data={clientData}/>
      case 2:
        return <Address data={clientData} />
      case 3: 
        return <BankDetails data={clientData}/>
      case 4:
        return <Other data={clientData}/>
      case 5:
        return <Nominee data={clientData} />
      default:
        return null
    }
  }
  return (
    <div>
      <div className="  ">
        <div >
          {/* <ul className="navbar-nav "> */}
          <ul className="flex bg-red p-3 rounded-t-lg justify-around">
            {STEPPER_TABS.map(item => (
              <li
                className={`cursor-pointer text-white ${item.id === activeTab.id ? 'active-tab text-white bg-blue rounded-lg py-1 px-3 ' : 'text-white'}`}
                onClick={() => handleOnClick(item)}
                key={item.id}
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="p-4 bg-cream">

      {activeTab && renderActiveComponent()}
      </div>
    </div>
  )
}

export { Stepper }

