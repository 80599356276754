import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Tick from "../../assets/succcess.png";

export default function MotherName(props) {
  const handleChange = (event) => {
    props.handleChange(event.target.name, event.target.value);
  };
  return (
    <div className="mother-input">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <div className="flex justify-between mt-4">
            <p className="mb-3 text-white">Mother Name</p>
            <div>
              <img src={Tick} alt="" width="30px" height="10px" />
            </div>
          </div>
          <input
            type="text"
            onChange={handleChange}
            name="motherName"
            placeholder="Mrs."
            className="p-4  w-56 rounded text-black"
          />
        </FormControl>
      </Box>
    </div>
  );
}
