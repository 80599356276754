import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

export default function PoliticallyExposed(props) {
  const [politicallyExposed, setPoliticallyExposed] = useState("NO");
  const handleGenderChange = (e) => {
    setPoliticallyExposed(e.target.value);
    props.handleChange(e.target.name, e.target.value);
  };

  useEffect(() => {
    props.handleChange("politicallyExposed", politicallyExposed);
  }, [politicallyExposed]);


  return (
    <div>
      <div className="flex justify-between mt-4">
        <p>Are you Politically Exposed ?</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="politicallyExposed"
            value="YES"
            // checked={props.formData.politicallyExposed === "YES"}
            checked={politicallyExposed === "YES"}
            onChange={handleGenderChange}
            className="mr-2"
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="politicallyExposed"
            value="NO"
            // checked={props.formData.politicallyExposed === "NO"}
            checked={politicallyExposed === "NO"}
            onChange={handleGenderChange}
            className="mr-2 ml-4"
          />
          No
        </label>
      </div>
    </div>
  );
}
