import React, { useEffect, useState } from "react";
import Tick from "../../assets/succcess.png";

export default function StockAccused(props) {
  const [actionTakenBySebiOrAnyOtherAuthority,setActionTakenBySebiOrAnyOtherAuthority] = useState("NO");
  const handleGenderChange = (e) => {
    if (e.target.value === "NO") {
      setActionTakenBySebiOrAnyOtherAuthority("NO");
      props.handleChange("actionTakenBySebiOrAnyOtherAuthorityDescription", "");
    }
    props.handleChange(e.target.name, e.target.value);
  };

  useEffect(()=> {
    props.handleChange("actionTakenBySebiOrAnyOtherAuthority", actionTakenBySebiOrAnyOtherAuthority);

  }, [actionTakenBySebiOrAnyOtherAuthority])

  return (
    <div>
      <div className="flex justify-between mt-4">
        <p className="w-[80%]">
          Do you have any action/ proceeding initiated/ pending taken by SEBI/
          stock exchange any other authority ?
        </p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>

      <div>
        <label>
          <input
            type="radio"
            name="actionTakenBySebiOrAnyOtherAuthority"
            value="YES"
            // checked={
            //   props.formData.actionTakenBySebiOrAnyOtherAuthority === "YES"
            // }
            checked={
              actionTakenBySebiOrAnyOtherAuthority === "YES"
            }
            onChange={handleGenderChange}
            className="mr-2"
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="actionTakenBySebiOrAnyOtherAuthority"
            value="NO"
            checked={
              props.formData.actionTakenBySebiOrAnyOtherAuthority !== "YES"
            }
            onChange={handleGenderChange}
            className="mr-2 ml-4"
          />
          No
        </label>
      </div>

      {props.formData.actionTakenBySebiOrAnyOtherAuthority === "YES" && (
        <input
          type="text"
          placeholder="Reason"
          value={
            props.formData.actionTakenBySebiOrAnyOtherAuthorityDescription
          }
          name="actionTakenBySebiOrAnyOtherAuthorityDescription"
          onChange={(e) =>
            props.handleChange(e.target.name, e.target.value)
          }
          className="mt-1 border-2 p-3 border-white w-56 rounded text-black"
        />
      )}
    </div>
  );
}
