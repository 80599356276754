import React from "react";

const Thanks = () => {
  return (
    <>
      <div className="h-60 flex flex-col justify-center items-center">
        <p className="mb-4">Thank you for your response</p>
        <p>
          Someone from
          <span className="color-tertiary font-bold"> RYZ Team</span> will
          contact you within 24hrs
        </p>
      </div>
    </>
  );
};

export  {Thanks};
