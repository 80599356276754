import React, { useState } from "react"
import Button from "@mui/material/Button"

const NOMINEE_DATA = [
  { key: "name", value: "Full Name" },
  { key: "email", value: "Email Id" },
  { key: "contactNumber", value: "Mobile Number", type: "number" },
  {
    key: "relation",
    value: "Relation",
    options: [
      "Spouse",
      "Son",
      "Daughter",
      "Father",
      "Mother",
      "Brother",
      "Sister",
      "Grand-Son",
      "Grand-Daughter",
      "Grand-Father",
      "Grand-Mother",
      "Not Provided",
      "Other",
    ],
  },
  {
    key: "sharePercentage",
    value: "Share Percentage",
    type: "number",
    placeholder: "Sum of Share Percentage must be 100",
  },
  { key: "dateOfBirth", value: "Date Of Birth" },
  {
    key: "typeOfDocument",
    value: "Type Of Identity Document",
    options: [
      "AadharCard",
      "PanCard",
      "Driving Licence",
      "Voter Id",
      "Passport Number",
    ],
  },
  { key: "poiReferenceNumber", value: "Proof Of Identity Reference Number" },
]

const Guardian_DETAILS = [
  { key: "guardianName", value: "Guardian Name" },
  {
    key: "guardianRelation",
    value: "Guardian Relation",
    options: [
      "Father",
      "Mother",
      "Sibling",
      "Father in law",
      "Mother in law",
      "Brother in law",
      "Sister in law",
    ],
  },
  { key: "guardianAddress", value: "Guardian Address" },
  {
    key: "guardianIdProof",
    value: "Guardian ID Proof",
    options: [
      "AadharCard",
      "PanCard",
      "Driving Licence",
      "Voter Id",
      "Passport Number",
    ],
  },
  { key: "guardianIDProofNumber", value: "Guardian ID Proof Number" },
]

const ADDRESS_FORMAT = [
  { key: "houseNumber", value: "Address" },
  { key: "street", value: "Street" },
  { key: "district", value: "City" },
  {
    key: "state", value: "State",
    options: [
      "ANDAMAN AND NICOBAR ISLANDS",
      "ANDHRA PRADESH",
      "ARUNACHAL PRADESH",
      "ASSAM",
      "BIHAR",
      "CHANDIGARH",
      "CHHATTISGARH",
      "DADRA AND NAGAR HAVELI",
      "DAMAN AND DIU",
      "DELHI",
      "GOA",
      "GUJARAT",
      "HARYANA",
      "HIMACHAL PRADESH",
      "JAMMU AND KASHMIR",
      "JHARKHAND",
      "KARNATAKA",
      "KERALA",
      "LAKSHADWEEP",
      "MADHYA PRADESH",
      "MAHARASHTRA",
      "MANIPUR",
      "MEGHALAYA",
      "MIZORAM",
      "NAGALAND",
      "ODISHA",
      "PUDUCHERRY",
      "PUNJAB",
      "RAJASTHAN",
      "SIKKIM",
      "TAMIL NADU",
      "TELANGANA",
      "TRIPURA",
      "UTTAR PRADESH",
      "UTTARAKHAND",
      "WEST BENGAL"
    ]
  },
  { key: "pincode", value: "Zip Code" },
  { key: "country", value: "Country" },
]

export default function Nominee(props) {
  const { handleChange, data, errorData } = props
  const [age, setAge] = useState(null)
  const [parentNames, setParentNames] = useState({ mother: "", father: "" })
  const handleAgeCheck = dateOfBirth => {
    const birthday = new Date(dateOfBirth)
    const today = new Date()
    let calculatedAge = today.getFullYear() - birthday.getFullYear()
    const m = today.getMonth() - birthday.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      calculatedAge--
    }
    setAge(calculatedAge)
  }

  const handleParentNameChange = (parent, name) => {
    setParentNames({ ...parentNames, [parent]: name })
  }
  const handleFileChange = (e) => {
    const files = e.target.files;
    const file = files[0];

    if (file.type === "application/pdf") {
      alert("Proof of Address should be an image, not a PDF");
      e.target.value = null; // Reset file input
    } else {
      // Proceed with file handling
      getBase64(file, e.target.name);
    }
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      // Extract the base64 data
      let base64Data = reader.result

      // Check if the base64 string contains the 'data:application/pdf;base64,' prefix
      const base64Prefix = "data:application/pdf;base64,"
      if (base64Data.startsWith(base64Prefix)) {
        // Remove the prefix from the base64 data
        base64Data = base64Data.substring(base64Prefix.length)
      }

      // Call handleChange with the modified base64 string
      // console.log(base64Data,"---------------------------data")
      props.handleChange("proofOfAddress", base64Data)
    }
  }

  // const renderDate = (item) => (
  //   <div className="flex flex-col text-white" key={item.key}>
  //     <span className="text-white">{item.value}</span>
  //     <input
  //       type="date"
  //       className=" text-black py-3.5 rounded px-2 mt-3 border-2 "
  //       onChange={(e) => {
  //         handleChange(item.key, e.target.value);
  //         handleAgeCheck(e.target.value);
  //       }}
  //     />
  //   </div>
  // );

  const renderDatePicker = item => (
    <div className="flex flex-col text-blue" key={item.key}>
      <span className="text-white font-thin">{item.value}</span>
      <input
        type="date"
        max="9999-12-31"
        className=" text-black py-4 rounded px-2 mt-3"
        onChange={e => {
          const selectedDate = e.target.value;
          // Split the date string into parts (YYYY, MM, DD)
          const [year, month, day] = selectedDate.split('-');
          // Rearrange the parts to the desired format (DD-MM-YYYY)
          const formattedDate = `${day}-${month}-${year}`;
          handleChange(item.key, formattedDate);
          handleAgeCheck(formattedDate);
        }}
      />
      {errorData.dateOfBirth && (
        <p className="text-white font-thin">
          ({errorData?.dateOfBirth ? "Date of Birth is required" : ""}*)
        </p>
      )}
      {/* Conditionally render mother and father inputs if age < 18 */}
      {age < 18 && (
        <>
          <p className="text-white font-thin underline">
            * Nominee age is lower than 18 years, and hence requires guardian
            details as well.
          </p>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 mt-2 gap-4">
            {Guardian_DETAILS.map((item, idx) =>
              item.key === "dateOfBirth"
                ? renderDatePicker(item, idx)
                : item.options
                  ? renderDropdown(item, idx)
                  : renderTextField(item, idx)
            )}
          </div>
        </>
      )}
    </div>
  )

  const renderDropdown = item => (
    <div className="flex flex-col" key={item.key}>
      <span className="text-white font-thin">{item.value}</span>
      <select
        className=" text-black py-3.5 rounded px-2 mt-3"
        // sx={{ border: "2px solid white", mt: 1, color: lightBlue[50],
        // '&.Mui-checked': {
        //   color: lightBlue[50],
        // }  }}
        value={data[item.key] || ""}
        onChange={event => handleChange(item.key, event.target.value)}
      >
        <option value="" disabled>
          {item.value}
        </option>
        {item.options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {errorData[item.key] && (
        <p className="text-white font-thin">
          ({errorData[item.key] ? `${item.value} is Required` : ""}*)
        </p>
      )}
    </div>
  )

  const renderProofOfAddress = () => (
    <div
      className="min-h-[80px] mt-1 p-4 rounded text-white"
      key="proofOfAddress"
    >
      <p className="text-white font-thin">Upload Address proof</p>
      <p className="text-white font-thin text-sm ">
        file size should be less than 1mb in Image format only (.JPG, .JPEG, .PNG)
      </p>
      <br />
      <input
        type="file"
        name="photo"
        accept="image/jpeg, image/png"
        className="font-thin text-white"
        onChange={handleFileChange}
      />

      {data.proofOfAddress && (
        <>
          <div className="pr-4 flex justify-center items-center">
            <img
              className="w-50% text-white font-thin"
              src={data.proofOfAddress}
              alt={`${data?.name} address proof`}
            />
          </div>
          <Button
            sx={{ backgroundColor: "#071945", color: "white" }}
            onClick={() => props.handleChange("proofOfAddress", "")}
          >
            x
          </Button>
        </>
      )}
      {errorData?.proofOfAddress && (
        <p className="text-white font-thin">
          ({errorData?.proofOfAddress ? "Proof of Address is required" : ""}*)
        </p>
      )}
    </div>
  )

  const renderTextField = item => (
    <div className="flex flex-col gap-2" key={item.key}>
      <span className="text-white font-thin">{item.value}</span>
      <input
        type={item.key === "contactNumber" ? "tel" : item?.type ?? "text"}
        id={item.key}
        onChange={event => handleChange(item.key, event.target.value)}
        placeholder={item?.placeholder ?? item.value}
        name={item.key}
        maxLength={item.key === "contactNumber" ? 10 : undefined}
        value={data[item.key] ?? ""}
        className="mt-1 p-4 rounded text-black"
      />
      {errorData[item?.key] && (
        <span className="text-white font-thin">
          ({errorData[item?.key] ? `${item?.value} is Required` : ""}*)
        </span>
      )}
    </div>
  )
  const renderAddressField = addressField => (
    <div className="flex flex-col gap-2" key={addressField.key}>
      <span className="font-thin">{addressField.value}</span>
      {addressField.key === 'state' ? ( // Check if the key is 'state'
        <select
          id={addressField.key}
          onChange={event => {
            const inputValue = event.target.value;
            handleChange(addressField.key, inputValue, "addressDto");
          }}
          value={data.addressDto?.[addressField.key] ?? ""}
          className="mt-1 border-2 p-4 border-white rounded text-black bg-white"
        >
          {/* Options for the dropdown */}
          <option value="">Select State</option>
          {addressField.options.map(option => (
            <option key={option.value} value={option.value}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <input
          type="text"
          id={addressField.key}
          maxLength="20"
          onChange={event => {
            const inputValue = event.target.value;
            if (inputValue.length <= 20) {
              handleChange(addressField.key, inputValue, "addressDto");
            }
          }}
          placeholder={addressField.value}
          name={addressField.key}
          value={data.addressDto?.[addressField.key] ?? ""}
          className="mt-1 border-2 p-4 border-white rounded text-black bg-white "
        />
      )}
      {errorData?.addressDto && errorData?.addressDto[addressField?.key] && (
        <span className="text-white font-thin">
          (
          {errorData?.addressDto[addressField?.key]
            ? `${addressField?.value} is Required`
            : " "}
          *)
        </span>
      )}
    </div>
  );


  return (
    <section className="lg:grid grid-cols-2 gap-4 mb-4 md:grid  sm:flex flex-col text-white">
      {NOMINEE_DATA.map((item, idx) =>
        item.key === "dateOfBirth"
          ? renderDatePicker(item, idx)
          : item.options
            ? renderDropdown(item, idx)
            : renderTextField(item, idx)
      )}
      {ADDRESS_FORMAT.map(renderAddressField)}
      {/* {ADDRESS_FORMAT.map((item, idx) =>
  item.key === "state"
    ? renderDropdown(item, idx)
    : renderAddressField(item, idx)
)} */}
      {renderProofOfAddress()}
    </section>
  )
}
