import React from "react"
import "./styles.css"
import {useParams} from "react-router-dom"

const BasicDetails = ({data}) => {
  const {clientId} = useParams();
  return (
    <>
      <div className="flex justify-between bg-cream">
        <p className="text-start font-bold text-lg">Basic Details</p>
        <p className="text-lg font-bold">Action</p>
      </div>
      <br/>
      <div className="overflow-y-auto h-[80vh] bg-cream">
        <div className="text-start">
          <label className="font-bold">PAN</label><br/>
          <input type="text" className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.pan?.panNumber} /><br/><br/>
          <label className="font-bold">Date Of Birth</label><br/>
          <input type="text" className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.pan?.dob}/><br/><br/>
          <label className="font-bold">Name as PAN</label><br/>
          <input type="text" className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.pan?.name}/><br/><br/>
          <label className="font-bold">Name as AADHAR</label><br/>
          <input type="text" className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.aadhar?.name ??""}/><br/><br/>
          <label className="font-bold">Father/ Husband Name</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.aadhar?.careOf ??""}/><br/><br/>
          <label className="font-bold">Mother Name</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userInformation?.motherName}/><br/><br/>
          <label className="font-bold">Gender</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.pan?.gender ??""}/><br/><br/>
          <label className="font-bold">Nationality</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userInformation?.nationality ??""}/><br/><br/>
          <label className="font-bold">Marital Status</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userInformation?.maritalStatus}/><br/><br/>
          <label className="font-bold">Text Residency Outside India</label><br/>
          <input type="text" className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userInformation?.taxResidence}/><br/><br/>
          <label className="font-bold">Email ID</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userDetail?.email ?? ""}/><br/><br/>
          <label className="font-bold">Mobile Number</label><br/>
          <input type="text"  className="w-full bg-white rounded-xl py-2 p-2" disabled value={data?.userDetail?.phoneNumber}/><br/><br/>
          {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default BasicDetails
