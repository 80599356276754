import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Loader from "../../components/loader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useApi from "../../hooks/useApi";
import "./styles.scss";
import RzyLogo from "../../assets/RYZ.svg";
import Login from "../../assets/mobileHome/login.png";
import RButton from "../../components/rButton";
import EKYC from "../../assets/mobileHome/eKyc.png";
import Ekyc from "../../assets/kyc/ekycLogo.png";
import EditButton from "../../assets/email/editButton.png";
import RImg from "../../components/rImg";
import ThankYou from "../../components/ThankYou/Thankyou.jsx"
import AOS from 'aos';
import 'aos/dist/aos.css'


function MobileLogin(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();
  const [showThankYou, setShowThankYou] = useState(false);


  const reDir = async () => {
    const pathName = window.location.pathname;
    if (pathName === "/legality/callback" || pathName === "/ryz-market/login") {
      const urlParams = new URLSearchParams(window.location.search);
      const phoneNumber = urlParams.get("phoneNumberr");
      const isBackoffice = urlParams.get("backoffice");
      if (phoneNumber && phoneNumber.length === 10) {
        setPhoneNumber(phoneNumber);
        handleSubmit(undefined, phoneNumber);

      }
    }
  };

  const reDirFn = async () => {
    // const history = useHistory();
    const pathName = window.location.pathname;
    if (pathName === "/legality/callback") {
      const urlParams = new URLSearchParams(window.location.search);
      const phoneNumber = urlParams.get("phoneNumber");
      const documentId = urlParams.get("documentId");
      const isRekyc = urlParams.get("isrekyc");

      if (phoneNumber && documentId) {
        const apiUrl = `https://kyc.finovo.tech:5555/api/v1/esign/register/callback?phone=${phoneNumber}&documentId=${documentId}&isrekyc=${isRekyc}`;
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add any additional headers if required
            },
            // You can add a body here if it's a POST request
          });

          if (response.ok) {
            // API call successful
            console.log('API call successful');
            setShowThankYou(true);
            // history.replace('/legality/callback');
            // You can handle the response data here
          } else {
            // Handle error cases
            console.error('API call failed');
          }
        } catch (error) {
          // Handle network errors
          console.error('Network error:', error);
        }
      }
    }
  };

  useEffect(() => {
    AOS.init();
    // AOS.refresh(); 
    reDirFn();
    reDir()
  }, []);

  const handleChange = (event) => {
    // Get the value entered by the user
    const inputValue = event.target.value;

    // Limit the input to 10 characters
    const limitedValue = inputValue.slice(0, 10);

    // Update the state with the limited value
    setPhoneNumber(limitedValue);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event, value) => {
    event?.preventDefault();
    // Perform validation
    const phone = value ?? phoneNumber;
    const regex =
      /^\+?([0-9]{1,3})?\s?\(?([0-9]{3})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const isValid = regex.test(phone);
    if (isValid) {

      props.handlePostRequest({ value: phone }, `otp/send?type=SMS`);

      setErrorMsg("");
      setValid(isValid);
    } else {
      setErrorMsg("* Invalid Phone Number");
    }
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest(
        { phone: phoneNumber, otp: otp },
        `otp/verify-mobile`
      );
    } else {
      setOtpErrorMsg("Invalid OTP");
    }
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
    setOtpErrorMsg("");
  };
  const handleEditNumber = () => {
    setValid(false);
  };



  return (
    <>
      <div>
        {showThankYou && <ThankYou />}
      </div>
      {!showThankYou && (<div>
        <form className="email-container flex justify-around items-center flex-wrap rounded-t-2xl p-3 bg-grey">
        <div className="left-container">
          <div className="email-container-content text-white text-6xl max-[1087px]:text-5xl max-[998px]:text-4xl">
            <Loader open={loading} />
            <p>Open your Ryz </p>
            <p> Market Account in</p>
            <p>just 5 minutes</p>
            <p className="note-main text-xs mt-6 text-white">
              NOTE: RYZ MARKET IS DOING CLIENT BASED <br /> TRADING AND PRO
              ACCOUNT TRADING.
            </p>
          </div>
        </div>
        {/* Right Container */}
        <div className="right-container">
          <div className="rounded-xl text-white border-2 border-white">
            <div className="flex justify-end p-4">
              <p className="font-bold text-lightGrey text-3xl px-4 pt-2 opacity-75">
                Login
              </p>
            </div>
            <div>
              {!valid && (
                <>
                  <div data-aos="fade-down" data-aos-duration="1500" className="login-container p-9 max-[998px]:p-5 overflow-hidden">
                    <div className="mb-3">
                      <p className="right-content text-2xl">
                        Enter your <br />
                        Mobile Number
                      </p>
                    </div>
                    <div className="mb-2">
                      <input
                        autoFocus={true}
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        placeholder="Enter mobile no."
                        className="numberInput p-3 rounded-full spin-button-none text-white"
                      />
                    </div>
                    <p className="text-red mb-5">{errorMsg}</p>
                    <div className="flex">
                      <input type="checkbox" checked className="me-4" />
                      <div className="text-sm max-[998px]:text-xs">
                        I agree to receive communication from Ryz
                        <br /> through SMS, Whatsapp, Email, and Calls.
                      </div>
                    </div>
                    <br />
                    <RButton
                      handleButtonClick={handleSubmit}
                      buttonName="Send otp"
                      bgColor="bg-tertiary"
                      externalClassName="p-2"
                      type={"submit"}
                    />
                  </div>
                </>
              )}
              {valid ? (
                <>
                  <div data-aos="fade-down" data-aos-duration="1500"  className="login-container p-9 max-[998px]:p-5 overflow-hidden">
                    <p className="right-content text-2xl mb-1">
                      Enter the OTP sent <br />
                      to your mobile no.
                    </p>
                    <div className="flex">
                      <p className="mb-3 opacity-70">{phoneNumber}</p>
                      <div onClick={handleEditNumber}>
                        <RImg
                          innerImgClassName="w-[20px]"
                          externalClassName="ml-4"
                          src={EditButton}
                          alt="edit"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <input
                        autoFocus
                        type="number"
                        name="otp"
                        id="otp"
                        value={otp}
                        onChange={handleOtpChange}
                        placeholder="Enter otp"
                        className="numberInput p-3 rounded-full spin-button-none text-white"
                      />
                    </div>

                    <div className="mob-button flex justify-between mt-3">
                      <p onClick={handleCancelOTP} className="cursor-pointer">
                        Cancel OTP
                      </p>
                      <p onClick={handleSubmit} className="cursor-pointer">
                        Resend
                      </p>
                    </div>
                    <RButton
                      handleButtonClick={handleOtpSubmit}
                      buttonName="Continue"
                      bgColor="bg-tertiary"
                      externalClassName="p-2 mt-2"
                      type={"submit"}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <p className="note text-xs mt-6 text-white">
            NOTE: RYZ MARKET IS DOING CLIENT BASED <br /> TRADING AND PRO
            ACCOUNT TRADING.
          </p>
        </div>
      </form>
      <div className="ekyc-container flex justify-end bg-lightBlack">
        <img className="ekyc-img w-[40%] mr-[9%]" src={Ekyc} alt="" />
        </div>
      </div>)}
    </>
    
  );
}

export { MobileLogin };
