import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

export default function MaritalStatus(props) {
  const [maritalStatus, setMaritalStatus] = useState("SINGLE");

  useEffect(() => {
    props.handleChange("maritalStatus", maritalStatus);
  }, []);

  const handleStatusChange = (e) => {
    if (e.target.value === "SINGLE") {
      props.handleChange("spouseName", "");
    }
    setMaritalStatus(e.target.value);
    props.handleChange(e.target.name, e.target.value);
  };

  return (
    <div className="marital-status-container text-white">
      <div className="flex justify-between">
        <p className="mt-4 mb-1">Marital Status</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <div className="radio-group">
        <label className="mr-3 font-thin">
          <input
            type="radio"
            name="maritalStatus"
            value="SINGLE"
            className="mr-1"
            checked={props.formData.maritalStatus === "SINGLE"}
            onChange={handleStatusChange}
          />
          Single
        </label>
        <label className="font-thin"> 
          <input
            type="radio"
            name="maritalStatus"
            value="MARRIED"
            className="mr-1"
            checked={props.formData.maritalStatus === "MARRIED"}
            onChange={handleStatusChange}
          />
          Married
        </label>
      </div>
      {props?.formData?.maritalStatus === "MARRIED" && (
        <input
          type="text"
          name="spouseName"
          onChange={(e) => props.handleChange(e.target.name, e.target.value)}
          placeholder="Spouse Name"
          className="mt-2 border-2 border-black p-3 w-56 rounded text-black"
        />
      )}
    </div>
  );
}
