import React, { useState } from "react";
import Loader from "../../components/loader";
import useApi from "../../hooks/useApi";
import RButton from "../../components/rButton";
import "./styles.scss";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";
import RImg from "../../components/rImg";
import EditButton from "../../assets/email/editButton.png";

function Login(props) {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [otp, setOtp] = useState("");
  const { loading, error, postData } = useApi();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);
    if (isValid) {
      postData(`otp/send?type=EMAIL`, { value: email });
      setErrorMsg("");
    } else {
      setErrorMsg("Invalid Email");
    }
    setValid(isValid);
  };

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    if (otp.trim().length === 4) {
      props.handlePostRequest({ email: email, otp: otp }, `otp/verify-email`);
      setErrorMsg("");
    } else {
      setErrorMsg("Invalid OTP");
    }
  };
  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
  };
  const handleEditEmail = () => {
    setValid(false);
  };
  return (
    <>
    <div className="h-screen">
      {/* navbar */}
      <section className="flex justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
        <div className="">
          <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
        </div>
        <div>
          <p className="text-red font-bold sm:text-xl md:text-2xl">LOGIN</p>
        </div>
      </section>
      {/* navbar end */}
      <form className="flex sm bg-cream flex-col h-[50vh] md:flex-row md:justify-between lg:justify-around md:items-center">
          <div className="text-blue sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl xxl:text-6xl">
            <Loader open={loading} />
            <div className="sm:p-10 sm:hidden lg:block">
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Nirman </p>
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Demat Account in</p>
            <p className="font-bold tracking-wider">just 5 minutes</p>
            <p className="text-xs md:mt-16 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED <br /> TRADING AND PRO
              ACCOUNT TRADING.
            </p>
            </div>
            <div className="sm:p-10 lg:hidden ">
            <p className="font-bold sm:mb-1 md:mb-2 tracking-wider">Open your Nirman Demat Account in just 5 minutes</p>
            <p className="sm:hidden md:block text-xs md:mt-16 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED TRADING AND PRO
              ACCOUNT TRADING.
            </p>
            </div>
        </div>
        {/* Right Container */}
        <div className="m-5">
        <div className="rounded-2xl text-white bg-red">
            <div>
              {!valid && (
                <>
                  <div className="login-container p-9 max-[998px]:p-5">
                    <div className="mb-3">
                      <p className="text-2xl">
                        Enter your Email Id
                      </p>
                    </div>
                    <div className="mb-2">
                      <input
                        autoFocus={true}
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={email}
                        onChange={handleChange}
                        placeholder="Enter email id"
                        className="p-3 rounded-full spin-button-none text-black lg:w-[300px]"
                      />
                    </div>
                    <div className="flex">
                      <input type="checkbox" checked className="me-4" />
                      <div className="text-xs lg:hidden">
                        I agree to receive communication from Nirman Pvt. Ltd. 
                        through SMS, Whatsapp, Email, and Calls.
                      </div>
                      <div className="text-xs sm:hidden lg:block">
                        I agree to receive communication from Nirman Pvt. Ltd.<br/> 
                        through SMS, Whatsapp, Email, and Calls.
                      </div>
                    </div>
                    <br />
                    <RButton
                      handleButtonClick={handleSubmit}
                      buttonName="Send otp"
                      bgColor="bg-navy"
                      externalClassName="p-2 text-white"
                      type={"submit"}
                    />
                  </div>
                </>
              )}
              {valid ? (
                <>
                  <div className="p-9">
                  <p className="sm:text-xl md:text-2xl">
                      Enter the OTP sent <br /> to your email id.
                    </p>
                    <div className="flex my-3">
                      <p className="opacity-70">{email}</p>
                      <div onClick={handleEditEmail}>
                        <RImg
                          innerImgClassName="w-[20px]"
                          externalClassName="ml-4"
                          src={EditButton}
                          alt="edit"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <input
                        autoFocus
                        type="number"
                        name="otp"
                        id="otp"
                        value={otp}
                        onChange={handleOtpChange}
                        placeholder="Enter otp"
                        className="p-3 rounded-full spin-button-none text-black md:w-[300px]"
                      />
                    </div>
                    <div className="my-3">
                      <p onClick={handleSubmit} className="cursor-pointer">
                        Resend
                      </p>
                    </div>
                    <RButton
                      handleButtonClick={handleOtpSubmit}
                      buttonName="Continue"
                      bgColor="bg-navy"
                      externalClassName="p-2 text-white"
                      type={"submit"}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <p className="md:hidden px-10 text-xs my-6 text-black">
              NOTE: NIRMAN PRIVATE LIMITED IS DOING CLIENT BASED TRADING AND PRO
              ACCOUNT TRADING.
            </p>
      </form>
      </div>
    </>
  );
}

export { Login };
