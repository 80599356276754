import React from "react";

const Other = ({ data }) => {
  let SegmetValue = "";
  if (data?.userInformation?.nseCash) {
    SegmetValue = "Cash";
  }
  if (data?.userInformation?.nseCds) {
    SegmetValue = SegmetValue + ", " + "CDS";
  }
  if (data?.userInformation?.nseFutureAndOption) {
    SegmetValue = SegmetValue + ", " + "F&O";
  }
  if (data?.userInformation?.nseMcx) {
    SegmetValue = SegmetValue + ", " + "MCX";
  }
  return (
    <>
      <div className='overflow-y-auto h-[80vh]'>
        <div className="flex justify-between">
          <p className="text-start font-bold">Other Details</p>
          <p className="font-bold">Action</p>
        </div>
        <div className="">
          <div className="text-start">
            <label className="font-bold">Education</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.education}
            />
            <br />
            <br />
            <label className="font-bold">Occupation</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.occupation}
            />
            <br />
            <br />
            <label className="font-bold">Annual Income</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.annualSalary}
            />
            <br />
            <br />

            <label className="font-bold">Spouse Name</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.spouseName}
            />
            <br />
            <br />

            <label className="font-bold">Trading Experience</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.tradingExperience}
            />
            <br />
            <br />
            <label className="font-bold">Client Code</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userDetail?.clientCode}
            />
            <br />
            <br />

            <label className="font-bold">Occupation</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.occupation}
            />
            <br />
            <br />
            <label className="font-bold">Nominee Check</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.nomineeCheck}
            />
            <br />
            <br />
            <label className="font-bold">Tax Residence</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.taxResidence}
            />
            <br />
            <br />
            <label className="font-bold">Net Worth</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.netWorth}
            />
            <br />
            <br />
            <label className="font-bold">Annual Salary</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.annualSalary}
            />
            <br />
            <br />
            <label className="font-bold">Politically Exposed</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.politicallyExposed}
            />
            <br />
            <br />
            <label className="font-bold">Segment</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={SegmetValue}
            />
            <br />
            <br />
            <label className="font-bold">Equity Plan</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.brokerageAmount}
            />
            <label className="font-bold">Nationality</label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={data?.userInformation?.nationality}
            />
            <br />
            <br />
            <label className="font-bold">
              Action Taken By Sebi Or Any Other Authority
            </label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={
                data?.userInformation?.actionTakenBySebiOrAnyOtherAuthority
              }
            />
            <br/>
            <br/>
            <label className="font-bold">
              Action Taken By Sebi Or Any Other Authority Description
            </label>
            <input
              type="text"
              disabled
              className="py-3 rounded-xl w-full px-3 bg-white"
              value={
                data?.userInformation
                  ?.actionTakenBySebiOrAnyOtherAuthorityDescription
              }
            />

            {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
            >
              Next
            </button>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Other;
