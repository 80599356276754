import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

export default function Nationality(props) {
  const [nationality, setNationality] = useState("INDIAN");

  const handleNationalityChange = (e) => {
    setNationality(e.target.value);
    props.handleChange(e.target.name, e.target.value);
  };

  useEffect(() => {
    props.handleChange("nationality", nationality);
  }, []);

  return (
    <div className="nationality-container">
      <div className="flex justify-between mt-4 text-white">
        <p className="">Nationality</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <div className="radio-group">
        <label className="mr-3 text-white font-thin">
          <input
            type="radio"
            name="nationality"
            value="INDIAN"
            className="mr-1"
            // checked={props.formData?.nationality === "INDIAN"}
            checked={nationality === "INDIAN"}
            onChange={handleNationalityChange}
          />
          Indian
        </label>
        <label className="text-white font-thin">
          <input
            type="radio"
            name="nationality"
            value="FOREIGN"
            className="mr-1"
            // checked={props.formData?.nationality === "FOREIGN"}
            checked={nationality === "FOREIGN"}
            onChange={handleNationalityChange}
          />
          Foreign
        </label>
      </div>
    </div>
  );
}
