import Button from "@mui/material/Button";
function Legality(props) {
  const handleRedirect = () => {
    window.location.href = props.data.message;
  };
  return (
    <>
      <div className="flex flex-col justify-around items-center h-[20vh] bg-navy">
        <div className="mr-8 flex justify-center text-white text-4xl underline underline-offset-8">Legality :-</div>
      <div className="flex justify-center">
      <Button variant="contained" sx={{backgroundColor:"#C30000"}} className="" onClick={handleRedirect}>
        Redirect 
      </Button>
      </div>
      </div>
    </>
  );
}

export { Legality };
