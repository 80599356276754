import "./App.css";
import KYC from "./kyc/index";
import BrokeragePlan from "./components/brokeragePlan.jsx"

import { useEffect } from "react";
function App() {

  useEffect(() => {}, []);
  return (

    <div className="App">
      <KYC/>
      {/* <BrokeragePlan/> */}
    </div>
  );
}

export default App;

// lkn4553djp