import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

const QUALIFICATION_DETAILS_CONSTANTS = [
  { key: "UNDER HIGH SCHOOL", value: "Under High School" },
  { key: "HIGH SCHOOL", value: "High School" },
  { key: "INTERMEDIATE", value: "Intermediate" },
  { key: "GRADUATION", value: "Graduation" },
  { key: "POST GRADUATION", value: "Post Graduation" },
  { key: "DOCTRATE", value: "Doctrate" },
  { key: "PROF. DEGREE", value: "Prof. Degree" },
  { key: "ILLITERATE", value: "Illiterate" },
];

export default function QualificationDetails(props) {
  const [education, setEducation] = useState("UNDER HIGH SCHOOL");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setEducation(newValue);
    props.handleChange(event.target.name, newValue);
  };

  useEffect(() => {
    props.handleChange("education", education);
  }, [education]);

  return (
    <div>
      <div className="flex text-white justify-between mt-4">
        <p className="">Qualification Details</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <div>
        <label>
          <select
            className="w-56 text-black py-3.5 rounded px-2 mt-2 border-2"
            value={props?.formData?.education}
            name="education"
            onChange={handleChange}
          >
            {QUALIFICATION_DETAILS_CONSTANTS.map((item) => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </label>
      </div>
    </div>
  );
}
