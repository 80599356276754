import React, { useState, useRef, useEffect } from "react";
import UploadIcon from "../../assets/segment/uploadIcon.png";
import "./styles.scss";
import AOS from 'aos';
import 'aos/dist/aos.css'

function Segment(props) {
  const [plan, setPlan] = useState("Plan A");
  const [incomeproof, setIncomeproof] = useState(null);
  const [fileName, setFileName] = useState("");
  const [checkboxValues, setCheckboxValues] = useState({
    nseFutureAndOption: false,
    nseCds: false,
    nseMcx: false,
  });
  const [open, setOpen] = useState(false);
  const [show, setShow] = React.useState(true);
  const [pro, setPro] = React.useState(false);
  const [err, setErr] = useState(null)
  const [couponCode, setCouponCode] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState("");
  const [coupon,setCoupon]= useState(null)
  const incomeproofInputField = useRef(null);
  const[normal,setNormal]=useState(true)
  const [brokerage,setBrokerage]=useState(true)
  const [brokerageP,setBrokerageP]=useState()
  const [segment,setSegment]=useState(false)
  const NormalBorkerage = [
    {
      text: "Delivery",
      price: "0",
      css: "p-2"
    },
    {
      text: "IPO",
      price: "0",
      css: "p-2"
    },
    {
      text: "Intraday",
      price: "₹14",
      css: "p-2"
    },
    {
      text: "Commodities",
      price: "₹14",
      css: "p-2"
    },
    {
      text: "Currencies",
      price: "₹14",
      css: "p-2"
    },
    {
      text: "F&O",
      price: "₹14",
      css: "p-2"
    }

  ]
  const BrokeragePlan = [
    {
      text: "Account Opening Charges",
      price: "₹500",
      css: "p-2"
    },
    {
      text: "GST (18%)",
      price: "₹90",
      css: "p-2"
    },
    {
      text: "Brokerage",
      price: "₹14 per trade",
      css: "p-2"
    },
    {
      text: "Total",
      price: "₹604",
      css: "font-bold p-2"
    }

  ]
  
  const handleClose = () => {
    props.handleKycStatus("PAYMENT");
    setOpen(false);
  }

  const handlePlan = (plan) => {
    console.log(`Handling plan: ${plan}`);
    const userId = sessionStorage.getItem("token");
    const userI = userId.replace(/["']/g, '');
    const url = `${process.env.REACT_APP_API_ENDPOINT}details/payment/brokerage/plan?plan=${plan}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "XuserId": userI
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        if (data.message) {
          setShow(false)
          setPro(true)
          sessionStorage.setItem("kycStatus", "\"SEGMENT\"");
          window.location.href = data.message;
        } else {
          console.error('Redirect URL not found in the response');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (couponCode === 'RYZFREE10') {
      console.log('Change');
      sessionStorage.setItem("kycStatus", "\"SEGMENT\"");
      window.location.reload()
      // Add the changes you want to make here
      setMessage('Coupon applied successfully!');
    } else {
      setMessage('Invalid coupon code');
      console.log("XXXXXXX");
    }
  };

  useEffect(() => {
    console.log("useEffect called"); // Verify useEffect is running
    AOS.init();
    const kycStat = sessionStorage.getItem("kycStatus");
    const userI = kycStat.replace(/["']/g, '');

    if (userI === "PAYMENT") {
      console.log("KYC status is PAYMENT");
      setOpen(true);
      setCoupon(true)
      // setPro(false)
      setShow(true);
    }
    if (userI === "SEGMENT") {
      console.log("KYC status is PAYMENT");
      setSegment(true)
      setShow(false);
      setBrokerage(false)
      setNormal(false)
      setCoupon(false)
      setPro(true)
    }
  }, []);

  const handleProceed = (event) => {
    event.preventDefault();
    if (
      Object.values(checkboxValues).some((value) => value === true) &&
      (handleShowUploadProof() ? Boolean(incomeproof) : true)
    ) {
      props.handlePostRequest(
        {
          ...checkboxValues, plan,
          
          
          financialProofDocument: incomeproof
        },
        "details/segment-details",
        true
      );
    } else {
      if (handleShowUploadProof() && !Boolean(incomeproof))
        setErr("You have not uploaded financial proof");

      else {
        setErr(
          "You have not selected any trading option. Please select at least one option"
        );
      }
    }
  };
  const handleShowUploadProof = () => {
    if (
      checkboxValues.nseFutureAndOption ||
      checkboxValues.nseCds ||
      checkboxValues.nseMcx
    ) {
      return true;
    }
    return false;
  };


  const handleCDSCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleFOCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleMCXCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Extract the base64 data
      let base64Data = reader.result;

      // Check if the base64 string contains the 'data:application/pdf;base64,' prefix
      const base64Prefix = "data:application/pdf;base64,";
      if (base64Data.startsWith(base64Prefix)) {
        // Remove the prefix from the base64 data
        base64Data = base64Data.substring(base64Prefix.length);
      }

      setIncomeproof(base64Data);
      // console.log(base64Data, "-------------------------incomeproof");
    };
  };

  const handleIncomeChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setFileName(file.name);
    // console.log(fileName, "---------------------------filename");
    getBase64(file, e.target.name);
  };


  return (
    <>
      <div data-aos="zoom-in" className="main-container bg-lightBlack p-10 rounded-t-2xl">
        {segment && (
            <>
            <p className="text-4xl text-white mb-4 "> Segment</p>
                  <div className="nse-container flex border-b border-white pb-[32px]">
          <div className="top-container flex">
            <div className="boxes flex flex-col gap-5 ">
              <div className="flex gap-[9px]">
                <p className="text-white">NSE : </p>
                <div>
                  <label className="flex gap-4">

                    <p className="text-white "> F&O</p>
                    <input
                      type="checkbox"
                      checked={checkboxValues.nseFutureAndOption}
                      onChange={handleFOCheckboxChange}
                      name="nseFutureAndOption"
                    />
                  </label>
                </div>
                <div>
                  <label className="flex gap-4">
                    <p className="text-white"> CDS</p>
                    <input
                      type="checkbox"
                      checked={checkboxValues.nseCds}
                      onChange={handleCDSCheckboxChange}
                      name="nseCds"
                    />
                  </label>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <label className="flex gap-3">
                    <p className="text-white"> MCX</p>
                    <input
                      type="checkbox"
                      checked={checkboxValues.nseMcx}
                      onChange={handleMCXCheckboxChange}
                      name="nseMcx"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        
            </>
          )}
        {/* ------------------------------------end of ist part----------- */}

        {handleShowUploadProof() && (
          <>
            <div className="border-b border-white py-[25px]">
              <p className="text-white text-2xl">Your Financial Proof</p>
              <p className="text-white text-lg py-[10px]">
                Only required if you want F&O, CDS and MCX trading. Not requires
                if you just want to buy and sell equity stock.
              </p>
              <p className="text-red text-lg ">
                (file size should be less than 4mb in PDF format only)
              </p>
              <div className="upload-container flex gap-5">
                <div className="upload-button flex bg-white rounded-lg p-2 h-[45px] mt-[14px]">
                  <img
                    className="m-2 w-[20px] h-[20px]"
                    src={UploadIcon}
                    alt=""
                  />
                  <button
                    name="incomeproof"
                    onClick={() => incomeproofInputField.current.click()}
                    className="font-bold  text-black text-xs opacity-50"
                  >
                    <span className="text-black  test-xs">
                      {fileName ? `${fileName}` : "Upload Financial Proof"}
                    </span>
                  </button>
                  <input
                    type="file"
                    onChange={handleIncomeChange}
                    ref={incomeproofInputField}
                    className="hidden"
                    accept=".pdf"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {normal && (
          <>
            <p className="text-4xl text-white mb-4 "> Normal</p>
            <div className="text-white p-2 sm:text-lg md:text-xl cursor-pointer">
              Normal Brokerage <span className="px-2 py-1 border-2 border-white text-black font-bold bg-secondary ml-10 rounded"
              onClick={()=>setBrokerageP(!brokerageP)}
              >View</span>
        </div>
          </>
             )}
        {brokerageP && (
          <div>
           {NormalBorkerage.map((item, index) => {
        return (
          <div key={index} className="flex justify-between text-white text-sm md:text-lg md:w-[50%]">
            <p className={`${item.css}`}>{item.text}</p>
            <p>{item.price}</p>
          </div>
        )
      })
    }
          <hr className="text-white p-2 md:w-1/2"/>
          </div>
        )}
        {brokerage && (
            <div>
               {
          BrokeragePlan.map((item, index) => {
            return (
              <div key={index} className="flex justify-between text-white items-center text-sm md:text-lg md:w-[50%]">
                <p className={`${item.css}`}>{item.text}</p>
                <p>{item.price}</p>
                
              </div>
            )
          })
            }
             <hr className="text-white p-2 md:w-1/2"/>
            </div>
          )}
        <span className="text-red">
          ({err}*)
        </span>
        {show && (
          <>
            <div className="">
              <div className="flex sm:flex-col md:flex-row gap-4">
                <button
                  className="h-[44px] md:w-[30%] bg-buttonBlue font-bold rounded-lg"
                  onClick={() => handlePlan('NORMAL')}
                >
                  Pay
                </button>
                {coupon && (
                  <>
                   <div className="flex bg-orange p-1 rounded-lg gap-4 items-center">
                  <label htmlFor="couponCode" className="font-bold text-sm">Coupon Code :</label>
                  {/* <label htmlFor="couponCode" className="font-bold">Coupon Code :</label> */}
                  <input
                    type="text"
                    id="couponCode"
                    placeholder="Enter code here..."
                    className="p-1 rounded w-full"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </div>
                <button
                className="bg-blue px-3 rounded font-bold"
                onClick={handleSubmit}
                >Submit</button>
                  </>
               )}
              </div>
            </div>
          </>
        )}
        {pro && (
          <>
            <div className="w-[10%]">
              <div className="">
                <button
                  className="h-[44px] w-[153px] bg-buttonBlue font-bold rounded-lg"
                  onClick={handleProceed}
                >
                  Proceeed
                </button>
              </div>
            </div>
          </>
        )}

      </div>
      {open && (
        <>
          {/* <div
            className="relative h-screen flex flex-col border-2 border-white w-full sm:overflow-scroll md:overflow-hidden"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              //   color: "black",
              backgroundColor: "grey",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            }}
          >
            <div className='w-full text-end'>
              <button className='border py-2 px-3 rounded bg-red text-white border-2 border-black'
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <p className='bg-blue text-white text-center rounded-lg rounded-b-none sm:text-lg md:text-2xl xl:text-3xl p-3 m-4'>
              Brokerage Plan
            </p>
            <div className='flex sm:flex-col md:flex-row sm:gap-4 md:gap-10 xl:gap-14 sm:overflow-y-scroll md:overflow-hidden'>
              <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
                <div className='flex items-center justify-between px-3 text-lg '>
                  <p>PLAN <span className='sm:visible md:hidden'>A</span></p>
                  <p className='sm:visible md:invisible border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                    onClick={() => handlePlan('GOLD')}
                  >GOLD</p>
                  <p className='sm:invisible md:visible'>A</p>
                </div>
                <hr className='mb-4' />
                <h3 className='xltext-lg underline'><span className='text-orange text-xl'>Rs 1500/- </span><br /> per month, Having 125 FREE ORDER</h3>
                <br />
                <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 4250 Rupees
                  It includes:</p>
                <ul className='list-disc p-4 sm:text-sm md:text-md'>
                  <li>Free A/C opening for which charge Rs 500</li>
                  <li>Free API for Trading and Market Data= Rs 2000</li>
                  <li>125 free order x Rs 14 = Rs 1750</li>
                  <li>Total package worth = Rs 4250</li>
                </ul>
                <br />
                <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
                <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3595/-</span>  </p>
                <hr className='xl:p-2' />
                <div className='w-full flex justify-center'>
                  <button className='bg-white text-black text-xl rounded px-3 py-2 font-bold sm:invisible md:visible'
                    onClick={() => handlePlan('GOLD')}
                  >GOLD</button>
                </div>
              </div>

              <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
                <div className='flex items-center justify-between px-3 text-lg '>
                  <p>PLAN <span className='sm:visible md:hidden'>B</span></p>
                  <p className='sm:visible md:invisible border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                    onClick={() => handlePlan('TITANIUM')}
                  >TITANIUM</p>
                  <p className='sm:invisible md:visible'>B</p>
                </div>
                <hr className='mb-4' />
                <h3 className='xl:text-lg underline'><span className='text-orange text-xl'>Rs 2500/-</span><br /> per month, Having 175 FREE ORDER</h3>
                <br />
                <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 6000 Rupees
                  It includes:
                </p>
                <ul className='list-disc p-4 sm:text-sm md:text-md'>
                  <li>Free A/C opening for which charge Rs 500</li>
                  <li>Free API for Trading and Market Data= Rs 2000</li>
                  <li>175 free order x Rs 14 = Rs 2450<br />
                    And 15 offline Order X Rs 70 = Rs 1050</li>
                  <li>Total package worth = Rs 6000</li>
                </ul>
                <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
                <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3500/- </span>  </p>
                <hr className='xl:p-2' />
                <div className='w-full flex justify-center'>
                  <button className='bg-white text-black text-xl rounded px-3 py-2 font-bold sm:invisible md:visible'
                    onClick={() => handlePlan('TITANIUM')}
                  >TITANIUM</button>
                </div>
              </div>
              <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
                <div className='flex justify-between items-center px-3 text-lg '>
                  <p>PLAN <span className='sm:visible md:hidden'>C</span></p>
                  <p className='sm:visible md:invisible border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                    onClick={() => handlePlan('PLATINUM')}
                  >PLATINUM</p>
                  <p className='sm:invisible md:visible'>C</p>
                </div>
                <hr className='mb-4' />
                <h3 className='xl:text-lg underline'><span className='text-orange text-xl'>Rs 5555/-</span><br /> Only for a Quarter( 3 months) Having 225 FREE ORDER</h3>
                <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 9150 Rupees
                  It includes:</p>
                <ul className='list-disc p-4 sm:text-sm md:text-md'>
                  <li>Free A/C opening for which charge Rs 500</li>
                  <li> Free API for Trading and Market Data= Rs 2000</li>
                  <li>225 free order x Rs 14 = Rs 3150<br />
                    And 50 offline Order X Rs 70 = Rs 3500</li>
                  <li> Total package worth = Rs 9150</li>
                </ul>
                <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
                <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3595/-</span>  </p>
                <hr className='xl:p-2' />
                <div className='w-full flex justify-center'>
                  <button
                    className='bg-white text-black text-xl rounded px-3 ml py-2 font-bold sm:invisible md:visible'
                    onClick={() => handlePlan('PLATINUM')}
                  >PLATINUM</button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="border bg-grey md:h-screen absolute left-0 top-0 px-4">
          <div className='relative md:pt-10 xl:pt-20'>
              <button className='bg-red text-white text-xl absolute  md:right-14 px-3 py-1 rounded-full sm:hidden md:block'
              onClick={handleClose}
              >close</button>
              <button className='bg-red text-white text-xl absolute  right-0 top-7 px-3 py-1 rounded-full sm:block md:hidden'
               onClick={handleClose}
              >X</button>
                    <p className='text-center text-white sm:text-2xl md:text-3xl xl:text-5xl m-auto sm:py-8'>Brokerage Plan</p>
                    <div className='flex sm:flex-col md:flex-row gap-6 items-center justify-center h-[80%] cursor-pointer'>
                        <div className='card text-center md:w-[30%] flex flex-col justify-center p-4 rounded-xl'>
                            <button className='border sm:text-xl md:text-2xl xl:text-3xl m-auto w-[90%] font-bold py-2 rounded-full bg-gradient-to-r from-gold via-lightGold to-gold'>
                                GOLD
                            </button>
                            <div>
                                <h2 className="p-6 sm:text-lg md:text-xl xl:text-2xl"><span className='line-through text-red sm:text-xl md:text-2xl xl:text-3xl'>&nbsp;₹4250&nbsp;</span> ₹1500/-only </h2>
                            </div>
                            <div>
                                <p className="sm:text-xl md:text-2xl xl:text-3xl text-center">Get this plan, worth of ₹4250 in ₹1500 now. </p>
                                <p className="text-xl p-3 text-center">This plan includes <br /><span>Read More...</span></p>
                                <p className="sm:text-md md:text-lg text-center">(Valid for 1 Month)</p>
                            </div>
                            <div className='flex justify-center items-center py-2'>
                    <button className='px-3 py-2 rounded-xl border bg-darkBlue text-white text-lg'
                       onClick={()=>handlePlan("GOLD")}
                    >Pay Now</button>
                            </div>
                        </div>
                        <div className='card text-center md:w-[30%] flex flex-col justify-center p-4 rounded-xl'>
                            <button className='border sm:text-xl md:text-2xl xl:text-3xl m-auto w-[90%] font-bold py-2 rounded-full bg-gradient-to-r from-silver via-white to-silver'>
                                TITANIUM
                            </button>
                            <div>
                                <h2 className="p-6 sm:text-lg md:text-xl xl:text-2xl"><span className='line-through text-red sm:text-xl md:text-2xl xl:text-3xl'>&nbsp;₹6000&nbsp;</span> ₹2500/-only </h2>
                            </div>
                            <div>
                                <p className="sm:text-xl md:text-2xl xl:text-3xl text-center">Get this plan, worth of ₹6000 in ₹2500 now. </p>
                                <p className="sm:text-lg md:text-xl p-3 text-center">This plan includes <br /><span>Read More...</span></p>
                                <p className="sm:text-md md:text-lg text-center">(Valid for 1 Month)</p>
                            </div>
                            <div className='flex justify-center items-center py-2'>
                    <button className='px-3 py-2 rounded-xl border bg-darkBlue text-white text-lg'
                    onClick={()=>handlePlan("TITANIUM")}
                    >Pay Now</button>
                            </div>
                        </div>
                        <div className='card text-center md:w-[30%] flex flex-col justify-center p-4 rounded-xl'>
                            <button className='border sm:text-xl md:text-2xl xl:text-3xl m-auto w-[90%] font-bold py-2 rounded-full bg-gradient-to-r from-platinum via-lightPlatinum to-platinum'>
                                PLATINUM
                            </button>
                            <div>
                                <h2 className="p-6 sm:text-lg md:text-xl xl:text-2xl"><span className='line-through text-red sm:text-xl md:text-2xl xl:text-3xl'>&nbsp;₹9150&nbsp;</span> ₹5555/- only </h2>
                            </div>
                            <div>
                                <p className="sm:text-xl md:text-2xl xl:text-3xl text-center">Get this plan, worth of ₹9150 in ₹5555 now. </p>
                                <p className="sm:text-lg md:text-xl p-3 text-center">This plan includes <br /><span>Read More...</span></p>
                                <p className="sm:text-md md:text-lg text-center">(Valid for 3 Months)</p>
                            </div>
                            <div className='flex justify-center items-center py-2'>
                    <button className='px-3 py-2 rounded-xl border bg-darkBlue text-white text-lg'
                       onClick={()=>handlePlan("PLATINUM")}
                    >Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        </>
      )}
    </>
  );
}

export { Segment };
