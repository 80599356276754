import React from 'react'
import Logo from "../../../assets/nirmanData/nirmanLogo.png"
import "./styles.css"
import { Link } from 'react-router-dom'

const Navbar = () => {
  const LogOut =() =>{
    sessionStorage.clear();
    window.location.reload();
  }
  return (
    <div>
    </div>
  )
}

export {Navbar}
