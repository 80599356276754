import React, { useState, useEffect } from "react";
import Tick from "../../assets/succcess.png";

const OCCUPATION_DETAILS_CONSTANTS = [
  { key: "PRIVATE SECTOR", value: "Private Sector" },
  { key: "PUBLIC SECTOR", value: "Public Sector" },
  { key: "GOVERNMENT SECTOR", value: "Government Sector" },
  { key: "BUSINESS", value: "Business" },
  { key: "PROFESSIONAL", value: "Professional" },
  { key: "AGRICULTURIST", value: "Agriculturist" },
  { key: "RETIRED", value: "Retired" },
  { key: "HOUSEWIFE", value: "House Wife" },
  { key: "STUDENT", value: "Student" },
  { key: "OTHER", value: "Other" },
];

export default function Occupation(props) {
  const [occupation, setOccupation] = useState("PRIVATE SECTOR");
  const [showInput, setShowInput] = useState(false);
  const handleChange = (event) => {
    const newValue = event.target.value;
    setOccupation(newValue);
    if (newValue === "other") {
      setShowInput(true);
      props.handleChange(event.target.name, "");
    } else {
      setShowInput(false);
      props.handleChange(event.target.name, newValue);
    }
  };
  useEffect(() => {
    props.handleChange("occupation", occupation);
  }, [occupation]);


  return (
    <div>
      <div className="flex justify-between text-white mt-4">
        <p className="">Occupation</p>
        <div>
          <img src={Tick} alt="" width="30px" height="10px" />
        </div>
      </div>
      <div>
        <label>
          <select
            className="w-56 text-black py-3.5 rounded px-2 mt-2 border-2 "
            value={props?.formData?.occupationDetails}
            name="occupation"
            onChange={handleChange}
          >
            {OCCUPATION_DETAILS_CONSTANTS.map((item) => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </label>
        {showInput && (
          <input
            type="text"
            onChange={(e) => props.handleChange(e.target.name, e.target.value)}
            name="occupation"
            placeholder="Enter occupation"
            className="ml-2 mt-1 uppercase  border-2 p-3 border-black w-56 rounded text-black "
          />
        )}
      </div>
    </div>
  );
}
