import React, { useState, useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";


const ClientSignature = ({ data,updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const { loading, error, postData, fetchData } = useApi();
  const [imageData, setImageData] = useState("");

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientSignature: imageData,
          }
        );
        toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.errror("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      setImageData(reader.result);
    };
  };

  const handleApiCall = async () => {
    if (remark?.length > 10) {
      try {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            SIGNATURE: remark,
          }
        );
        toast.success("Rejected successfully");
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>SIGNATURE</b>${remark}`
    updateRejectData({ SIGNATURE: newRem });
  }

  useEffect(() => {
    const storedRemark = localStorage.getItem("signature");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("signature", remark);
  }, [remark]);
 


  return (
    <div>
      <ToastContainer />
      <Loader open={loading} />
      <div className="document-container border-2 border-cream">
        <div className="panImg-container border-2 border-cream">
          <img
            src={data?.imageAndSignature?.signature}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSignature;
