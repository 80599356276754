import React from 'react'
import Ryz1 from "../../assets/aadharProcedureMobile/adharUserName.png"
import Ryz2 from "../../assets/aadharProcedureMobile/aadharMobile.png"
import Ryz3 from "../../assets/aadharProcedureMobile/aadharOtp.png"
import Ryz4 from "../../assets/aadharProcedureMobile/aadharDigi.png"
import Ryz5 from "../../assets/aadharProcedureMobile/aadarRefresh.png"
import Ryz6 from "../../assets/aadharProcedureMobile/aadharAgree.png"
import Ryz7 from "../../assets/aadharProcedureMobile/aadharOTP2.png"
import DigiOne from "../../assets/aadharWeb/digiOne.jpg";
import DigiTwo from "../../assets/aadharWeb/digiTwo.jpg";
import DigiThree from "../../assets/aadharWeb/digiThree.jpg";
import DigiFour from "../../assets/aadharWeb/digiFour.jpg";
import DigiFive from "../../assets/aadharWeb/digiFive.jpg";
import DigiSix from "../../assets/aadharWeb/digiSix.png";
import DigiSeven from "../../assets/aadharWeb/digiSeven.png";
import RImg from "../../components/rImg";

const DigiLockerBrochure = () => {
  return (
    <div>
      <div className='aadhar-mob bg-white px-8 lg:none md:none sm:block'>
            <div className='text-center mb-4'>
                <h1 className='font-bold '>Step 1.</h1>
                <p className=''>First you have to visit the <b>‘DIGILOCKER APP’</b>.<br />
                    If you don't have digiLocker App, just download digiLocker from this link <a href='https://play.google.com/store/apps/details?id=com.digilocker.android'> <span className='text-skyBlue font-bold'>"<u>DIGILOCKER APP</u>"</span>
                    </a>
                </p>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 2.</h2>
                <p className=''>Then sign in to your account by entering your mobile no or aadhaar no or username  and security PIN.</p>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 3.</h2>
                <p className=''>Put the OTP and press the SUBMIT button</p>
            </div>
            <div className='flex flex-wrap justify-around'>
                <div>
                    <RImg
                        src={Ryz1}
                        alt={''}
                        width='400px'
                        innerImgClassName='w-[70%] image-container'
                        externalClassName='w-[33%] flex justify-center'
                    />
                </div>
                <div>
                    <RImg
                        src={Ryz2}
                        alt={''}
                        width='400px'
                        innerImgClassName='w-[70%]'
                        externalClassName='w-[33%] flex justify-center'
                    />
                </div>
                <div>
                    <RImg
                        src={Ryz3}
                        alt={''}
                        width='400px'
                        innerImgClassName='w-[70%]'
                        externalClassName='w-[33%] flex justify-center'
                    />
                </div>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 4.</h2>
                <p className=''>Now press to the ‘ISSUED’ button & press the 3 dot bar of right side of the  aadhar card segment.</p>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 5.</h2>
                <p className=''>Now press the REFRESH button.</p>
            </div>
            <div className='flex flex-wrap justify-evenly'>
                <div>
                    <RImg
                        src={Ryz4}
                        alt={''}
                        externalClassName=''
                        innerImgClassName='h-[60vh]'
                    />
                </div>
                <div>
                    <RImg
                        src={Ryz5}
                        alt={''}
                        externalClassName=''
                        innerImgClassName='h-[60vh]'
                    />
                </div>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 6.</h2>
                <p className=''>Come to the ‘VERIFY YOURSELF’ section & press the ‘I AGREE’ button.</p>
            </div>
            <div className='text-center m-4'>
                <h2 className='font-bold '>Step 7.</h2>
                <p className=''>Now put the OTP which is sent to your aadhaar linked registered mobile no & press the UPDATE button.</p>
            </div>
            <div className='flex flex-wrap justify-evenly'>
                <div>
                    <RImg
                        src={Ryz6}
                        alt={''}
                        externalClassName=''
                        innerImgClassName='h-[60vh]'
                    />
                </div>
                <div>
                    <RImg
                        src={Ryz7}
                        alt={''}
                        externalClassName=''
                        innerImgClassName='h-[60vh]'
                    />
                </div>
            </div>
        </div>
        <main className="aadhar-web px-9 lg:block md:block sm:none">
        <div className="p-5 pl-5">
          <p>
            <span>
              <b>Step: 1</b>
            </span>&nbsp;&nbsp;
            First you have to click the link{" "}
            <span className="text-skyBlue">
              <a href="https://www.digilocker.gov.in/">
                ‘https://www.digilocker.gov.in/’
              </a>
            </span>
          </p>
          <p>
            <span>
              <b>Step: 2</b>
            </span>&nbsp;&nbsp;
            Then sign in to your account by entering your mobile no or aadhaar
            no or username and security PIN.
          </p>
          <p>
            <span>
              <b>Step: 3</b>
            </span>&nbsp;&nbsp;
            Put the OTP and press the SUBMIT button.
          </p>
        </div>
        <div className="flex flex-wrap justify-around mx-4">
          <div>
            <RImg
              src={DigiOne}
              alt={""}
              width="400px"
              innerImgClassName="w-[70%]"
              externalClassName="w-[33%] flex justify-center"
            />
          </div>
          <div>
            <RImg
              src={DigiTwo}
              alt={""}
              width="400px"
              innerImgClassName="w-[70%]"
              externalClassName="w-[33%] flex justify-center"
            />
          </div>
          <div>
            <RImg
              src={DigiThree}
              alt={""}
              width="400px"
              innerImgClassName="w-[70%]"
              externalClassName="w-[33%] flex justify-center"
            />
          </div>
        </div>
        <div className="p-5">
          <p>
            <span>
              <b>Step: 4</b>
            </span>
            &nbsp;&nbsp;&nbsp;Now come to the ‘ISSUED DOCUMENT’ section & press
            the 3 dot bar of right side of the aadhar card segment.
          </p>
          <div>
            <RImg
              src={DigiFour}
              alt={""}
              externalClassName="py-5"
              innerImgClassName=""
            />
          </div>
          <p>
            <b>Step: 5</b>&nbsp;&nbsp; Now press the REFRESH button.
          </p>
          <RImg
            src={DigiFive}
            alt={""}
            externalClassName="py-5 "
            innerImgClassName=""
          />
          <p>
            <b>Step: 6</b>&nbsp;&nbsp; Come to the update aadhaar section, select the ‘I
            CONSENT TO DIGILOCKER’ button & press the UPDATE button
          </p>
          <RImg
            src={DigiSix}
            alt={""}
            externalClassName="py-5"
            innerImgClassName=""
          />
          <p>
            <b>Step: 5</b> &nbsp;&nbsp;Now put the OTP which is sent to your aadhaar linked
            registered mobile no & press the UPDATE button.
          </p>
          <RImg
            src={DigiSeven}
            alt={""}
            externalClassName="py-5"
            innerImgClassName=""
          />
        </div>
      </main>
    </div>
  )
}

export  {DigiLockerBrochure}
