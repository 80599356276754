import React, { useEffect,useState } from "react";
import Lion from "../../assets/verification/lion.svg";
import Flag from "../../assets/verification/flagText.svg";
import QR from "../../assets/verification/qr.png";
import Bharat from "../../assets/verification/bharatText.svg";
import "./styles.scss";
import RButton from "../../components/rButton";
import Verified from "../../assets/verification/verified.png"
import EKYC from "../../assets/verification/eKycBack.png"
import KYC from "../../assets/verification/eKyc.png"
import RImg from "../../components/rImg"
import Loader from "../../components/loader";
import RzyLogo from "../../assets/RYZ.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'

function Verification(props) {
  const { aadharResponseData, panCardDataDto } = props.data;
  const [load, setLoad] = useState(false)
  
  useEffect(() => {
    AOS.init();
  })

  const handleClick = ()=>{
    sessionStorage.clear();
    window.location.reload();
    setLoad(true)
    setTimeout(() => {
      setLoad(false)
    },5000)
    
  }
      if (!aadharResponseData) {
        return (<>
        <Loader open={ load} />
        <div className="email-container flex flex-col gap-20 items-center flex-wrap rounded-2xl p-3 bg-grey h-[90vh]">
          <div className="text-white text-[100px]">Oop's !</div>
          <div className="grey italic font-bold text-center rounded text-xl border px-2 w-[400px] p-4 bg-orange border border-white hover:border-2 cursor-text">
            <p>
              You have uploaded aadhar details previoiusly
              Kindly try another <br /> aadhar !
            </p>
            <button type="submit" className="bg-darkBlue mt-10 text-white font-thin p-2 rounded-lg border hover:border-2 border-white cursor-pointer"
            onClick={handleClick}
            >submit</button>
          </div>
        </div>
      </>)
      }
    
  return (
    <>
      <div className="verification-page flex flex-row rounded-t-2xl">
        <div className="left-head w-[50%] flex  items-center">
          <div className="verified-left flex flex-col justify-center px-12
          py-16">
            <p className="left-up text-white text-3xl  flex items-center gap-2">
              Verified <RImg
                src={Verified}
                alt="ryzKyc"
                width="35px"
                externalClassName="flex justify-center items-center"
                innerImgClassName="verified-tick"
              />
            </p>
            <p className="left-mid bg-silver text-xl text-white opacity-60">
              Aadhar & PAN
            </p>
            <div className="web-btn pt-6 max-[550px]:hidden" >
              <RButton
                buttonName="Proceed"
                handleButtonClick={() => props.handleKycStatus("PERSONAL_INFO")}
                bgColor=""
                color
                border
                text
                externalClassName="verified-btn"
              />
            </div>
          </div>
        </div>
        <div className="right-head w-[50%] flex flex-col justify-center items-center">
          <div className="right-box border border-white p-4 rounded-xl w-[100%]  my-10 mr-2 ">
            <div className="aadharPan-wrapper flex flex-col w-full">
              <div data-aos="fade-down"
                //  data-aos-easing="linear"
                data-aos-duration="1500" className="aadhar-card w-[100%] border rounded-lg bg-white mb-4">
                <div className="flex justify-around mb-2 ">
                  <img src={Lion} alt="" width="8%" />
                  <img src={Flag} alt="" width="70%" />
                </div>
                <div className="flex">
                  <div className="aadhar-image m-2">
                    <img
                      src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                        }`}
                      alt="Aadhar card of user"
                      className="border"
                      width="80px"
                    />
                  </div>
                  <div className="aadhar-info font-bold">
                    <p>Name: {aadharResponseData?.name ?? ""}</p>
                    <p>DOB: {panCardDataDto?.dob ?? ""}</p>
                    <p>Gender: {panCardDataDto?.gender ?? ""} </p>
                  </div>
                </div>
                <div className="aadhar-no text-center my-3 font-bold text-xl">

                  {aadharResponseData?.maskAadharNumber ?? ""}
                  <hr className="text-red" />
                </div>
                <div className="text-center font-bold">
                  आधार - आम आदमी का अधिकार
                </div>
              </div>
              <div data-aos="fade-up"
                data-aos-duration="1500" className="pan-card w-[100%] border rounded-lg">
                <div className="flex justify-around">
                  <div className="">
                    <p className=" pan-heading1 text-sm text-center">आयकर विभाग</p>
                    <p className="pan-heading ">INCOME TAX DEPARTMENT</p>
                  </div>
                  <img src={Lion} alt="lion" width="8%" />
                  <img src={Bharat} alt="" width="40%" />
                </div>
                <div className="flex">
                  <img
                    src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                      }`}
                    alt="Pan card of user"
                    width="100px"
                    height="100px"
                    className="pan-image border ml-2"
                  />
                  <div className="flex justify-center w-[60%]">
                    <p className="pan-no">PAN CARD NUMBER</p>
                    <span className="font-bold "> {panCardDataDto?.panNumber ?? ""}</span>
                  </div>

                </div>
                <div className="aadhar-info ml-2">
                  <p>NAME: <span className="font-bold"> {panCardDataDto?.name ?? ""}</span></p>
                  <p>FATHER NAME: <span className="font-bold"> {aadharResponseData?.careOf ?? ""}</span></p>
                  <p>Date Of Birth:<span className="font-bold"> {panCardDataDto?.dob ?? ""}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-btn w-[100%] ml-12  mb-6 min-[550px]:hidden">
            <RButton
              buttonName="Proceed"
              handleButtonClick={() => props.handleKycStatus("PERSONAL_INFO")}
              bgColor=""
              color
              border
              text
              externalClassName="verified-btn"
            />
          </div>
        </div>
      </div>

    </>
  );
}

export { Verification };
