import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RButton from "../../components/rButton";

function UploadPanProof(props) {
const [formData, setFormData] = useState({
    panCardImage: "",
    panNumber: "",
    dob: "",
    name: "",
});
const [error, setError] = useState("");
const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
};

const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
     // Extract the base64 data
     let base64Data = reader.result;

     // Check if the base64 string contains the 'data:application/pdf;base64,' prefix
     const base64Prefix = 'data:application/pdf;base64,';
     if (base64Data.startsWith(base64Prefix)) {
        // Remove the prefix from the base64 data
        base64Data = base64Data.substring(base64Prefix.length);
     }

     // Update the form data
     setFormData(prev => ({ ...prev, [name]: base64Data }));
    };
};
const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
};

const handleDate = (obj) => {
    return `${obj.$D}/${obj.$M + 1}/${obj.$y}`;
};

const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
};

const handleSubmit = () => {
    // Write code for null check
    const isFormValid =
     formData.name &&
     formData.dob &&
     formData.panNumber &&
     formData.panCardImage;
    if (isFormValid) {
     setError("");
     props.handlePostRequest(formData, "upload/pancard");
    } else {
     setError("Please fill all field as required");
    }
};

return (
    <><div className="h-screen">
    <div className="flex flex-col justify-center items-center bg-grey p-8">
     <h1 className="p-4 mb-4 font-bold text-blue">Upload PAN Details</h1>
     <span className="text-white">
        Pan card is not linked with DIGI LOCKER so please upload pancard related
        information manually
     </span>
     <div>
        <div className="flex flex-col gap-2">
         <span className="font-semibold text-blue">Full Name*</span>
         <TextField
            id={"name"}
            onChange={(e) => handleChange("name", e.target.value)}
            placeholder={"Enter full name as per Pan Card"}
            name={"name"}
            sx={{color:"white",border:"2px solid white", borderRadius:"12px",backgroundColor:"white"}}
            variant="outlined"
            value={formData.name ?? ""}
         />
        </div>
        <hr className="my-5 text-white" />
        <div className="flex flex-col">
         <span className="font-semibold text-blue">
            {"Date of Birth"}
         </span>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
             name={"dob"}
            sx={{color:"white",border:"2px solid white", borderRadius:"12px",backgroundColor:"white"}}
             onChange={(newValue) => handleChange("dob", newValue.format("DD-MM-YYYY"))}
             renderInput={(params) => <TextField {...params} />}
            />
         </LocalizationProvider>
        </div>
        <hr className="my-5 text-white" />
        <div className="flex flex-col gap-2">
         <span className="font-semibold text-blue">Pan Number</span>
         <TextField
            id={"panNumber"}
            onChange={(e) => handleChange("panNumber", e.target.value.toUpperCase())}
            placeholder={"Enter Pan Number as per Pan Card"}
            sx={{color:"white",border:"2px solid white", borderRadius:"12px",backgroundColor:"white"}}
            name={"panNumber"}
            variant="outlined"
            value={formData.panNumber ?? ""}
         />
        </div>
     </div>
     <hr className="my-5 text-white" />
     <div className="flex flex-col gap-1 mb-4">
        <div className="flex text-blue flex-col justify-center items-center">
         <span className="mb-4 font-bold">
            <u>Upload PAN Proof</u> 
         </span>
         <p className="text-white">
        (file size should be less than 2mb)
     </p>
         <input
            type="file"
            accept=".pdf"
            name="panCardImage"
            onChange={onChange}
         />
         {formData.panCardImage && (
            <>
             <div className="w-1/4 h-50 p-4">
                <img
                 className="w-full"
                 src={formData.panCardImage}
                 alt="bank validation proof"
                />
             </div>
             <Button
                variant="outlined"
                color="error"
                onClick={() => cancelImage("panCardImage")}
             >
                Cancel
             </Button>
            </>
         )}
        </div>
        <div className="self-end flex justify-center w-full">
         {error && <span className="text-white">{error}</span>}
        </div>
        <div className="flex justify-center w-full">
         <RButton
            handleButtonClick={handleSubmit}
            buttonName="Submit"
            bgColor="bg-white"
                  className=""
         />
        </div>
     </div>
    </div>
    </div></>
);
}

export { UploadPanProof };