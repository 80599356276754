// import React, { useEffect, useState } from "react";
// import useApi from "../../hooks/useApi";
// import RButton from "../../components/rButton";
// import { Modal } from "@mui/material";
// import Logo from "../../assets/nirmanData/nirmanLogo.png";
// import CameraCapture from "../../components/captureImage";
// import "./styles.scss";

// const Reject = (props) => {
//   const { loading, error, postData, fetchData } = useApi();
//   const [imageCapture, setImageCapture] = useState(false);
//   const [response, setResponse] = useState("");
//   const [formData, setFormData] = useState({
//     clientImage: "",
//     clientSignature: "",
//     bankProof: "",
//     financialProof: "",
//     panCard: "",
//   });

//   useEffect(() => {
//     FetchData();
//   }, []);

//   const FetchData = async () => {
//     const userId = sessionStorage.getItem("token");
//     const userI = userId.replace(/["']/g, '');
//     try {
//       const response = await fetch("https://ekyc.finovo.tech:5555/api/v1/rejected/user/list", {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "xuserid": userI,
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setResponse(data);
//       } else {
//         console.error("Failed to fetch data:", response.status, response.statusText);
//       }
//     } catch (error) {
//       console.error("Error during data fetching:", error);
//     }
//   };

//   const cancelImage = (name) => {
//     const keyMapping = {
//       IMAGE: "clientImage",
//       SIGNATURE: "clientSignature",
//       BANK_PROOF: "bankProof",
//       FINANCIAL_PROOF: "financialProof",
//       PAN_CARD: "panCard",
//     };
//     setFormData((prev) => ({ ...prev, [keyMapping[name]]: "" }));
//   };

//   const handleSendData = () => {
//     props.handlePostRequest(  formData , "rejected/document/upload");
//   };

//   const onChange = (e) => {
//     const files = e.target.files;
//     const file = files[0];
//     const keyMapping = {
//       PAN_CARD: "panCard",
//       IMAGE: "clientImage",
//       SIGNATURE: "clientSignature",
//       FINANCIAL_PROOF: "financialProof",
//       BANK_PROOF: "bankProof",
//     };
//     getBase64(file, keyMapping[e.target.name]);
//   };

//   const getBase64 = (file, name) => {
//     let reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       // Keep the entire data URL, including the prefix
//       let base64Data = reader.result;
//       setFormData((prev) => ({ ...prev, [name]: base64Data }));
//     };
//   };

//   const handleCaptureImage = async (val) => {
//     if (val && typeof val === "string" && val.startsWith("data:image/")) {
//       const compressedImage = await compressImage(val, true); // Pass true to keep the prefix
//       setFormData((prev) => ({ ...prev, clientImage: compressedImage }));
//     } else {
//       alert("Please capture a valid image or ensure your camera is working.");
//     }
//   };

//   const compressImage = (imageDataUrl, keepPrefix = false) => {
//     return new Promise((resolve) => {
//       const img = new Image();
//       img.src = imageDataUrl;
//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         const MAX_WIDTH = 400;
//         const scaleSize = MAX_WIDTH / img.width;
//         canvas.width = MAX_WIDTH;
//         canvas.height = img.height * scaleSize;
//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//         // When compressing, specify the format to ensure the prefix matches
//         const compressedImage = canvas.toDataURL("image/png");
//         resolve(compressedImage);
//       };
//     });
//   };
//   return (
//     <>
//       <div className="flex">
// <img src={Logo} alt=""  className="sm:w-[70px] md:w-[100px]"/>
//       </div>
//        <div className="bg-red py-4 h-screen overflow-y-scroll">
// <p className="text-white text-center underline underline-offset-4 text-2xl">Please provide rejected Documents </p>
//       <div>
//       {response && response.map((item, idx) => (
//         <div key={response[idx]} className="flex items-center py-6 text-white">
//           <p className="w-1/2 text-center text-white text-xl">{response[idx]}</p>
//           <div className="w-1/2 flex justify-start">
//           {response[idx] === "IMAGE" ? (
//                 <div>{!formData.IMAGE && (
//                   <RButton
//                     buttonName="Enable Camera"
//                     handleButtonClick={() => setImageCapture(true)}
//                     bgColor="text-black"
//                     color
//                     border
//                     text
//                     externalClassName="bg-white"
//                   />
//                 )}
//                 {formData.IMAGE && (
//                   <>
//                     <div>
//                       <img
//                         className="border-2 w-[250px] h-[200] rounded-lg"
//                         src={formData.IMAGE}
//                         alt=""
//                       />
//                     </div>
//                     <div className="mt-2">
//                       <button
//                         className="reject-btn"
//                         onClick={() => cancelImage("IMAGE")}
//                       >
//                         Cancel
//                       </button>
//                     </div>
//                   </>
//                 )}
//               <Modal open={imageCapture} onClose={() => setImageCapture(false)}>
//           <div className="flex justify-start">
//             <CameraCapture
//               handleCaptureImage={handleCaptureImage}
//               imageValue={formData.IMAGE}
//               onSave={() => setImageCapture(false)}
//             />
//           </div>
//                 </Modal>
//               </div>
//               ) : (
//                 <input
//                   type="file"
//                   name={response[idx]}
//                   onChange={onChange}
//                   accept=".pdf,image/*"
//                 />
//               )}
//           </div>
//         </div>
//       ))}
//         </div>
//         <div className="flex justify-center">

//       <button className="reject-btn"
//             onClick={handleSendData}>
//         Submit
//       </button>
//         </div>
//       <div className="flex justify-center m-auto">
//       </div>
//     </div>
//     </>
//   )
// }

// export { Reject }

import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import RButton from "../../components/rButton";
import { Modal } from "@mui/material";
import Logo from "../../assets/RYZ.png";
import CameraCapture from "../../components/captureImage";
import "./styles.scss";

const Reject = (props) => {
  const { loading, error, postData, fetchData } = useApi();
  const [imageCapture, setImageCapture] = useState(false);
  const [response, setResponse] = useState([]);
  const [formData, setFormData] = useState({
    clientImage: "",
    clientSignature: "",
    bankProof: "",
    financialProof: "",
    panCard: "",
  });

  useEffect(() => {
    FetchData();
  }, []);

  const FetchData = async () => {
    const userId = sessionStorage.getItem("token");
    const userI = userId.replace(/["']/g, '');
    try {
      const response = await fetch("https://kyc.finovo.tech:5555/api/v1/rejected/user/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "xuserid": userI,
        }
      });

      if (response.ok) {
        const data = await response.json();
        setResponse(data);
        initializeFormData(data);
      } else {
        console.error("Failed to fetch data:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  };

  const initializeFormData = (data) => {
    const initialData = {};
    data.forEach(item => {
      const key = keyMapping[item];
      initialData[key] = "";
    });
    setFormData(prev => ({ ...prev, ...initialData }));
  };

  const keyMapping = {
    IMAGE: "clientImage",
    SIGNATURE: "clientSignature",
    BANK_PROOF: "bankProof",
    FINANCIAL_PROOF: "financialProof",
    PAN_CARD: "panCard",
  };

  const cancelImage = (name) => {
    const key = keyMapping[name];
    setFormData((prev) => ({ ...prev, [key]: "" }));
  };

  const isFormValid = () => {
    return response.every(item => {
      const key = keyMapping[item];
      return formData[key]?.trim() !== "";
    });
  };

  const handleSendData = () => {
    if (!isFormValid()) {
      alert("Please fill all the required fields.");
      return;
    }

    const filteredFormData = {};
    response.forEach(item => {
      const key = keyMapping[item];
      filteredFormData[key] = formData[key];
    });

    console.log("Payload:", filteredFormData); // Debugging log to see the payload

    props.handlePostRequest(filteredFormData, "rejected/document/upload");
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    const key = keyMapping[e.target.name];

    if (file.type.startsWith('image/')) {
      getBase64(file, key, true);
    } else {
      getBase64(file, key, false);
    }
  };

  const getBase64 = (file, name, isImage) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64Data = reader.result.split(',')[1];
      if (isImage) {
        base64Data = 'data:image/' + file.type.split('/')[1] + ';base64,' + base64Data;
      }
      setFormData((prev) => ({ ...prev, [name]: base64Data }));
    };
  };

  const handleCaptureImage = async (val) => {
    if (val && typeof val === "string" && val.startsWith("data:image/")) {
      const compressedImage = await compressImage(val);
      setFormData((prev) => ({ ...prev, clientImage: compressedImage }));
    } else {
      alert("Please capture a valid image or ensure your camera is working.");
    }
  };

  const compressImage = (imageDataUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const compressedImage = canvas.toDataURL("image/jpeg");
        resolve(compressedImage);
      };
    });
  };

  return (
    <>
      <div className="bg-grey py-4 h-screen overflow-y-scroll">
        <p className="text-white text-center underline underline-offset-4 text-2xl">Please provide rejected Documents</p>
        <div>
          {response && response.map((item, idx) => (
            <div key={idx} className="flex items-center py-6 text-white">
              <p className="w-1/2 text-center text-white text-xl">{item}</p>
              <div className="w-1/2 flex justify-start">
                {item === "IMAGE" ? (
                  <div>{!formData.clientImage && (
                    <RButton
                      buttonName="Enable Camera"
                      handleButtonClick={() => setImageCapture(true)}
                      bgColor="text-black"
                      color
                      border
                      text
                      externalClassName="bg-white"
                    />
                  )}
                  {formData.clientImage && (
                    <>
                      <div>
                        <img
                          className="border-2 w-[250px] h-[200] rounded-lg"
                          src={formData.clientImage}
                          alt=""
                        />
                      </div>
                      <div className="mt-2">
                        <button
                          className="reject-btn"
                          onClick={() => cancelImage("IMAGE")}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                    <Modal open={imageCapture} onClose={() => setImageCapture(false)}>
                      <div className="flex justify-start">
                        <CameraCapture
                          handleCaptureImage={handleCaptureImage}
                          imageValue={formData.clientImage}
                          onSave={() => setImageCapture(false)}
                        />
                      </div>
                    </Modal>
                  </div>
                ) : (
                  <input
                    type="file"
                    name={item}
                    onChange={onChange}
                    accept=".pdf,image/*"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <button className="reject-btn" onClick={handleSendData}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export { Reject };