import React, { useState, useEffect } from "react";
import PdfPreview from "../../components/pdfViewer";



const Nominee2 = ({data, updateRejectData}) => {
  const [remark, setRemark] = useState("");
  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>SECOND NOMINEE PROOF</b>${remark}`
    updateRejectData({ NOMINEE_TWO: newRem });
  }
  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee2");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee2", remark);
  }, [remark]);
 


  return (
    <div>
      <div className="document-container border-2 border-cream">
        <div className="panImg-container border-2 border-cream">
        {data?.nomineeDetails[1]?.proofOfAddress ? (
            // If proofOfAddress exists, render PdfPreview
            <PdfPreview base64String={data?.nomineeDetails[1]?.proofOfAddress} />
          ) : (
            // If proofOfAddress doesn't exist, render an image or a message
            data?.nomineeDetails[1]?.proofOfAddress ? (
              // If nomineeDetails exist and have length, render image
              <img
                src={data.nomineeDetails[1]?.proofOfAddress || ""}
                alt=""
                width="100%"
                height="100%"
              />
            ) : (
              // If nomineeDetails is empty, render a message
              <p>Invalid Nominee format or pdf was not uploaded by the user</p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Nominee2;
