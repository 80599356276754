import React, { useState } from "react";
import "./styles.css";
import UserAdmin from "../userAdmin";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
// import Logo from "../../assets/nirmanData/nirmanLogo.png";
import { ClientIdLogin } from "../../rmLogin/ClientIdLogin";
import RmIdLogin from "../../kyc/ClientIdLogin";
import Home from "../views/home";

function UserLogin() {
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [userInfo, setUserInfo] = useState({
    username: "",
    userpassword: "",
    role:"RM"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        "https://kyc.finovo.tech:5556/api/v1/login/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: userInfo.username,
            userpassword: userInfo.userpassword,
            role: userInfo.role,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("authToken", data.token);
        sessionStorage.setItem("name", data.name);
        setMsg(data.msg);
        setValid(true);
      } else {
        setMsg("Login Failed");
        setValid(false);
      }
    } catch (error) {
      console.error(error);
      setMsg("An error occurred during login");
      setValid(false);
    } finally {
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  if (valid === false) {
    return (
      <>
        <div className="h-screen">
          <div>
            {/* <img width="100px" src={Logo} /> */}
          </div>
          <div className="flex justify-center items-center h-[65vh]">
            <div className="w-[60%] mx-auto border-2 border-lightGrey rounded-lg ">
              <div className="flex">
                <div className="bg-grey w-1/2 text-center text-white py-8 px-4  tracking-wider flex flex-col justify-center items-center">
                  <p className="mb-3 text-xl">Open your Trading and demat Account <hr /></p>
                  <p>How to open an account with us:</p>
                  <p>Instant and 100% paperless.
                    All your details are verified.
                    E-Sign, and you are all set ti trade.</p>
                </div>
                <div className="p-8 w-1/2 bg-cream rounded-b-lg">
                  <p className="font-bold text-xl text-center mb-3">RM/BM/AP Login </p>
                  <div className="flex justify-center">
                    <input
                      className="text-xl border-2  p-3 rounded-lg w-1/2"
                      type="text"
                      name="username"
                      value={userInfo.username}
                      onChange={handleChange}
                      placeholder="Enter your RM Code / Mobile no"
                    />
                  </div>
                  <p className="font-bold text-xl text-center my-3">RM/BM/AP Password</p>
                  <div className="flex justify-center items-center">
                    <input
                      className="text-xl border-2 p-3 rounded-lg w-1/2"
                      type={showPassword ? 'text' : 'password'}
                      name="userpassword"
                      value={userInfo.userpassword}
                      onChange={handleChange}
                      placeholder="Enter your password"
                      />
                    <span onClick={togglePasswordVisibility} className="">
                      {showPassword ? (<FaEyeSlash className="text-xl ml-1 rounded-lg rounded-s-none" />) : (<FaEye className="text-xl ml-1 rounded-lg rounded-s-none" />)}
                    </span>
                  </div>
                  {msg && <p className="text-danger">{msg}</p>}{" "}
                  <div className="flex justify-center mt-4">
                    <button
                      className="bg-blue py-2 px-4 rounded text-white"
                      type="submit"
                      onClick={handleLogin}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* < RmIdLogin/> */}
        <Home/>
      </>
    );
  }
}

export default UserLogin;
