import React from "react"

function Payment() {

  const handleRedirect = pageUrl => {
    window.open(pageUrl)
  }
  return (
    <div>
      <a
        href="https://www.instamojo.com/@RYZMARKETS/l05e4dfca35c3442fbe48cc3cdeb32e68/"
        rel="im-checkout"
        data-text="Pay"
        data-css-style="color:#ffffff; background:#75c26a; width:300px; border-radius:4px"
        data-layout="vertical"
        onClick={handleRedirect}
      >Payment</a>
      <script  src="https://js.instamojo.com/v1/button.js"></script>
    </div>
  )
}

export { Payment }
