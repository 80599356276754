import React, { useState, useEffect } from "react";
import { VERIFICATION_TABS } from "../../constant/MapperConstant";
import { PanInfo } from "../panInfo";
import { ClientImage } from "../ClientImage";
import ClientSignature from "../SignatureImage";
import AddressProof from "../AddressProof";
import BankProof from "../BankProof";
import FinancialProof from "../FinancialProof";
import Nominee1 from "../Nominee1";
import Nominee2 from "../Nominee2";
import { useParams } from "react-router-dom";
import Nominee3 from "../Nominee3";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
const Slider = ({ clientData }) => {
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const { clientId } = useParams();
  const [activeTab, setActiveTab] = useState(VERIFICATION_TABS[0]);

  const [rejectData, setRejectData] = useState({
    PAN_CARD: "",
    IMAGE: "",
    SIGNATURE: "",
    BANK_PROOF: "",
    FINANCIAL_PROOF: "",
    NOMINEE_ONE: "",
    NOMINEE_TWO: "",
    NOMINEE_THREE: "",
  });

  const handleOnClick = (item) => {
    setActiveTab(item);
  };

  const handleFinalSubmit = async () => {
    // console.log("Reject Remarks:", rejectData);
    const token = sessionStorage.getItem("authToken");
    try {
      setLoader(true);

      const res = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/reject/send/rejection/mail/${clientId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(rejectData),
        }
      );

      if (res.ok) {
        console.log("Submission successful");
        toast.success("Request Sent Successfully");
        // You can perform additional actions upon successful submission
      } else {
        console.error("Submission failed");
        toast.error("Failed to Send");
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("Error during submission");
      // Handle error during submission
    } finally {
      setLoader(false);
    }
  };

  const updateRejectData = (newRejectData) => {
    // setRejectData(newRejectData);
    setRejectData((prevRejectData) => ({
      ...prevRejectData,
      ...newRejectData,
    }));
    console.log("--------------", newRejectData);
  };

  const renderActiveComponent = () => {
    switch (activeTab.id) {
      case 1:
        return (
          <PanInfo data={clientData} updateRejectData={updateRejectData} />
        );
      case 2:
        return (
          <ClientImage data={clientData} updateRejectData={updateRejectData} />
        );
      case 3:
        return (
          <ClientSignature
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 4:
        return <AddressProof data={clientData} />;
      case 5:
        return (
          <BankProof data={clientData} updateRejectData={updateRejectData} />
        );
      case 6:
        return (
          <FinancialProof
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 7:
        return (
          <Nominee1 data={clientData} updateRejectData={updateRejectData} />
        );
      case 8:
        return (
          <Nominee2 data={clientData} updateRejectData={updateRejectData} />
        );
      case 9:
        return (
          <Nominee3 data={clientData} updateRejectData={updateRejectData} />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div>
        <Loader open={loader} />
        <div className="m-2 flex justify-end">
          <button
            onClick={() => navigation("/")}
            type="button"
            className="bg-navy text-white rounded-xl px-4 py-2 border"
          >
            back
          </button>
        </div>
        <div className="flex p-3 bg-red overflow-x-auto rounded-t-lg">
          {VERIFICATION_TABS.map((item) => (
            <li
              className={`slide-tabs ${
                item.id === activeTab.id
                  ? "active-tab text-white bg-blue rounded-lg"
                  : "text-white"
              }`}
              onClick={() => handleOnClick(item)}
              key={item.id}
            >
              {item.text}
            </li>
          ))}
        </div>
        {activeTab && renderActiveComponent()}
      </div>
      <div className="mt-4">
      </div>
    </>
  );
};

export default Slider;
