import React from "react";
import "./styles.css";

const Address = ({ data }) => {
  return (
    <>
      <div className="overflow-y-auto h-[80vh]">
        <div className="flex justify-between">
          <p className="font-bold text-lg">Permanent Address</p>
          <p className="text-lg font-bold">Action</p>
        </div>
        <div juclName="permanent-address">
          <div className="text-start">
            <label className="font-bold">Address</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.houseNumber}
            />
            <br />
            <br />
            <label className="font-bold">Addess Line 2</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.location}
            />
            <br />
            <br />
            <label className="font-bold">Voting Center</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.votingCenter}
            />
            <br />
            <br />
            <label className="font-bold">District / City</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.district}
            />
            <br />
            <br />
            <label className="font-bold">State</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.state}
            />
            <br />
            <br />

            <label className="font-bold">Country</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.country}
            />
            <br />
            <br />
            <label className="font-bold">PinCode</label>
            <input
              type="text"
              disabled
              className="rounded-xl py-2 w-full p-2 bg-white"
              value={data?.aadhar?.aadharAddressDto?.pincode}
            />
            <br />
            <br />
            {/* <div className="next-btn px-3">
            <button
              type="button"
              className="btn border border-2 border-dark px-3"
              >
              Next
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export { Address };
