

const ThankYou = () => {
  return (
    <>
      <div className="thankyou-wrapper rounded-t-2xl justify-center flex bg-lightBlack h-[80dvh] w-full">

        <div className="thankyou-bottom flex items-center justify-center ">
          <div className="thankyou-text flex flex-col text-center gap-4">
            <p className="text-heading font-bold text-4xl text-white self-center">
              Congrats <br />
              for showing Interest !
            </p>
            <p className="text-bottom  text-white text-xl">
              Thank you for showing interest in trading with RYZ. <br />
              Our support team will contact you shortly.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export { ThankYou };
