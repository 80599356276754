import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import useApi from "../../hooks/useApi";
import Box from "@mui/material/Box";
import Loader from "../../components/loader";
import Button from "@mui/material/Button";
import BankIcon from "../../assets/bankAccount/bankIcon.png";
import Logo from "../../assets/extra/footerLogo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
function BankAccount(props) {
  const { loading, error } = useApi();
  const [bankAcNo, setbankAcNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [accType, setAccType] = useState("SAVING");
  const [errorIfscCode, setErrorIfscCode] = useState("");
  const [ifsc, setIfsc] = useState("");
  const handleIFSC = async () => {
    try {
      setErrorIfscCode("");
      const response = await fetch(`https://ifsc.razorpay.com/${ifsc}`);
      if (response.ok) {
        const data = await response.json();
        // Assuming bankAcNo is defined somewhere in your code.
        // Replace "bankAcNo" with the actual variable containing the account number.
        (await data) &&
          props.handlePostRequest(
            { ifsc: ifsc, accountNumber: bankAcNo , accountType: accType}, 
            "details/bank-details"
          );
      } else {
        setErrorIfscCode("* Invalid IFSC code") 
        toast.error("Invalid IFSC code. Please check and try again.");
        // Handle the case when the API response is not successful (e.g., show an error message).
      }
    } catch (error) {
      // Handle any errors that occur during the API call (e.g., show an error message).
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setbankAcNo(event.target.value);
    if (bankAcNo.trim().length <= 17 && bankAcNo.trim().length >= 4) {
      setErrorMsg("");
    } else {
      setErrorMsg("* Invalid Account no.");
    }
  };

  const handleIfscChange = (event) => {
    event.preventDefault();
    const newIfsc = event.target.value.trim().toUpperCase();
    setIfsc(newIfsc);

    if (newIfsc.length >= 8) {
      setErrorIfscCode(""); // Clear the error message when IFSC length is greater than or equal to 4
    } else {
      setErrorIfscCode("* Invalid IFSC");
    }
  };
  const handleFinalSubmit = (event) => {
    event.preventDefault();
    if (bankAcNo.trim().length <= 17 && bankAcNo.trim().length >= 4) {
      setErrorMsg("");
      if (ifsc.trim().length < 4) {
        setErrorIfscCode("* Invalid IFSC");
        toast.error("Invalid IFSC code. Please check and try again.");
      } else {
        setAccType("SAVING");
        handleIFSC();
      }
    } else {
      setErrorMsg("Invalid Bank account");
    }
  };
  const handleAccTypeChange = (event) => {
    setAccType(event.target.value);
  };

  return (
    <>
      <Loader open={loading} />
      <div className="bg-grey p-16 rounded-t-2xl">
        <div className="text-white flex justify-between flex-wrap-reverse">
          <div className="w-[80%]">
            <p className="text-3xl">Bank Details </p>
            <p className="text-xl mt-1">Enter your Bank Details</p>
          </div>
          <div>
            <img className="bank-logo w-[5rem] " src={BankIcon} alt="" />
          </div>
        </div>
        <form noValidate style={{ marginTop: '1.25rem' }}>
          <>
            <div className="flex flex-wrap">
              <div className="detailsInput mr-12">
                <label className="text-white">Account No.</label>
                <br/>
                <input
                className="p-2 rounded-lg"
                   style={{
                    border: errorMsg ? '1px solid red' : '1px solid #ccc',
                  }}
                  type="number"
                  id="bankAcNo"
                  name="bankAcNo"
                  placeholder="Enter your account no"
                  autoComplete="bankAcNo"
                  value={bankAcNo}
                  onChange={handleChange}
                  required
                />
                <p className="text-red">{errorMsg}</p>
              </div>
              <div>
                <label className="text-white">IFSC Code</label>
                <input
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    width: '100%',
                    padding: '0.5rem',
                    border: error || errorIfscCode ? '1px solid red' : '1px solid #ccc',
                  }}
                  type="text"
                  id="ifsc"
                  name="IFSC"
                  placeholder="Enter IFSC Code"
                  value={ifsc}
                  onChange={handleIfscChange}
                  required
                />
                <p className="text-red">{error || errorIfscCode}</p>
              </div>
              <div>
                <label className="text-blue font-bold hidden">Account Type</label>
                      <br />
                      <select
                        name="account"
                        id="account-select"
                        onChange={handleAccTypeChange}
                        className="hidden"
                      >
                        <option value="">--Please choose an option--</option>
                        <option value="SAVING">Saving</option>
                        <option value="CURRENT">Current</option>
                      </select>
                </div>
            </div>
            
          </>

          <>
            <div className="mt-10">
              <button onClick={handleFinalSubmit} className="bg-blue font-bold p-3 rounded-lg">
                Validate
              </button>
            </div>
          </>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}

export { BankAccount };
