import React from "react";

const Thanks = () => {
  return (
    <>
      <div className="h-screen ">
      <div className="h-80 bg-red text-white text-xl flex flex-col justify-center items-center">
        <p className="mb-4">Thank you for your response</p>
        <p className="text-center">
          Someone from
          <span className="color-tertiary font-bold"> Nirman Team</span> will
          contact you within 24hrs.
        </p>
      </div>
      </div>
    </>
  );
};

export  {Thanks};
