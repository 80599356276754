import React, { useState, useEffect } from "react";
import PdfPreview from "../../components/pdfViewer";



const Nominee1 = ({data , updateRejectData}) => {
  const [remark, setRemark] = useState("");

  const handleConsole =()=>{
    const newRem = remark ==="" ? "" : `<b>FIRST NOMINEE PROOF</b>${remark}`
    updateRejectData({ NOMINEE_ONE: newRem });
  }


  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee1");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee1", remark);
  }, [remark]);
 

  return (
    <div>
      <div className="document-container border-2 border-cream">
        <div className="panImg-container border-2 border-cream">
          
        {data?.nomineeDetails[0]?.proofOfAddress ? (
            // If proofOfAddress exists, render PdfPreview
            <img
              src={`data:image/png;base64,${
                data?.nomineeDetails[0]?.proofOfAddress??""
              }`}
              alt=""
              width="100%"
              height="100%"
            />
          ) : (
            // If proofOfAddress doesn't exist, render an image or a message
            data?.nomineeDetails[0]?.proofOfAddress ? (
              <PdfPreview base64String={data?.nomineeDetails[0]?.proofOfAddress} />
              // If nomineeDetails exist and have length, render image

            ) : (
              // If nomineeDetails is empty, render a message
              ""
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Nominee1;
