import React, { useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import Zero from "../../assets/brokerage/zeroLogo.png";
import Cross from "../../assets/brokerage/crossIcon.png";
import Offer from "../../assets/brokerage/offer.svg";
import Arrow from "../../assets/segment/arrowButton.svg";
import UploadIcon from "../../assets/segment/uploadIcon.png";
import "./styles.scss";
import NirmanLogo from "../../assets/nirmanData/nirmanLogo.png";

function Segment(props) {
  const [brokerageAmount, setBrokerageAmount] = useState("0.00");
  const [plan, setPlan] = useState("Plan A");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [incomeproof, setIncomeproof] = useState(null);
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState({
    incomePassword: "",
  });
  const [checkboxValues, setCheckboxValues] = useState({
    nseCash: false,
    nseFutureAndOption: false,
    bseCds: false,
    bseFutureAndOption: false,
    bseCash:false,
  });

  const Brokerage = {
    zero: "₹ 1000 / -",
    free: "₹ 0 / -",
  };
  const [error, setError] = useState("");
  const incomeproofInputField = useRef(null);

  const handleProceed = (event) => {
    event.preventDefault();
    if (
      Object.values(checkboxValues).some((value) => value === true) &&
      (handleShowUploadProof() ? Boolean(incomeproof) : true)
    ) {
      props.handlePostRequest(
        { ...checkboxValues, plan, financialProofDocument: incomeproof },
        "details/segment-details",
        true
      );
    } else {
      if (handleShowUploadProof() && !Boolean(incomeproof))
        setError("You have not uploaded financial proof");
      else {
        setError(
          "You have not selected any trading option. Please select at least one option"
        );
      }
    }
  };
  const handleShowUploadProof = () => {
    if (
      checkboxValues.nseFutureAndOption ||
      checkboxValues.bseCds ||
      checkboxValues.bseFutureAndOption
    ) {
      return true;
    }
    return false;
  };

  const handleNSECashCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleBSECashCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleBSECDSCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleNSEFOCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };
  const handleBSEMCXCheckboxChange = (event) => {
    setError("");
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };


  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Extract the base64 data
      let base64Data = reader.result;

      // Check if the base64 string contains the 'data:application/pdf;base64,' prefix
      const base64Prefix = "data:application/pdf;base64,";
      if (base64Data.startsWith(base64Prefix)) {
        // Remove the prefix from the base64 data
        base64Data = base64Data.substring(base64Prefix.length);
      }

      setIncomeproof(base64Data);
      // console.log(base64Data, "-------------------------incomeproof");
    };
  };

  const handleIncomeChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    setFileName(file.name);
    // console.log(fileName, "---------------------------filename");
    getBase64(file, e.target.name);
  };

  const bill = () => {
    setBrokerageAmount("1000.00");
    setPlan("Plan A");
    handleClose();
  };
  const bill2 = () => {
    setBrokerageAmount("0.00");
    setPlan("Plan B");
    handleClose();
  };

  return (
    <>
       <div className="h-screen">
        {/* navbar */}
        <section className="flex justify-between sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
          <div className="">
            <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
          </div>
          <div>
            <p className="text-red font-bold sm:text-xl md:text-2xl ">
              SEGMENT
            </p>
          </div>
        </section>
        {/* navbar end */}
        <div className="main-container py-[50px] px-[99px] rounded-t-2xl">
          <p className="text-4xl text-white font-bold mb-4 "> Segment</p>
          <div className="nse-container flex border-b border-blue pb-[32px]">
              <div className="boxes flex flex-col flex-wrap gap-5 w-[50%] border-r-2 border-black ">
                <div className="flex gap-6">
                  <p className="text-white font-bold">NSE : </p>
                  <div>
                    <label className="flex gap-3">
                      <p className="text-white font-bold">Cash</p>
                       <input
                        type="checkbox"
                        checked={checkboxValues.nseCash}
                        onChange={handleNSECashCheckboxChange}
                        name="nseCash"
                      />
                    </label>
                  </div>
                  <div>
                    <label className="flex gap-4">
                      <p className="text-white font-bold"> F&O</p>
                      <input
                        type="checkbox"
                        checked={checkboxValues.nseFutureAndOption}
                        onChange={handleNSEFOCheckboxChange}
                        name="nseFutureAndOption"
                      />
                    </label>
                  </div>
                  
                </div>
                <div className="flex gap-6">
                    <p className="text-white font-bold">BSE : </p>
                  <div className=" flex gap-6">
                    <div>
                    <label className="flex gap-3">
                      <p className="text-white font-bold">Cash</p>
                       <input
                        type="checkbox"
                        checked={checkboxValues.bseCash}
                        onChange={handleBSECashCheckboxChange}
                        name="bseCash"
                      />
                    </label>
                  </div>
                    <div>
                      <label className="flex gap-3">
                        <p className="text-white font-bold">F&O</p>
                        <input
                          type="checkbox"
                          checked={checkboxValues.bseFutureAndOption}
                          onChange={handleBSEMCXCheckboxChange}
                          name="bseFutureAndOption"
                        />
                      </label>
                    </div>
                    <div>
                    <label className="flex gap-4">
                      <p className="text-white font-bold"> CDS</p>
                      <input
                        type="checkbox"
                        checked={checkboxValues.bseCds}
                        onChange={handleBSECDSCheckboxChange}
                        name="bseCds"
                      />
                    </label>
                  </div>
                  </div>
                </div>
              </div>

              {/* <div className="brokerage flex w-[50%] gap-7 justify-end md:px-8 ">
                <p className="text-2xl text-white font-bold">
                  View Brokerage charges
                </p>
                <img
                  src={Arrow}
                  alt="modalButton"
                  onClick={handleOpen}
                  className="arrow hover:cursor-pointer"
                />
              </div> */}
          </div>

          {/* ------------------------------------end of ist part----------- */}

          {handleShowUploadProof() && (
            <>
              <div className="border-b border-black py-[50px]">
                <p className="text-white font-bold text-3xl">
                  Your Financial Proof
                </p>
                <p className="text-white font-bold text-xl py-[20px]">
                  Only required if you want F&O, CDS and MCX trading. Not
                  requires if you just want to buy and sell equity stock.
                </p>
                <p className="text-white font-bold text-xl">
                  (file size should be less than 1mb in PDF format only)
                </p>
                <div className="upload-container flex gap-5">
                  <div className="upload-button flex border-2 border-black bg-blue rounded-lg p-2 h-[45px] mt-[14px]">
                    <img
                      className="m-2 w-[20px] h-[20px]"
                      src={UploadIcon}
                      alt=""
                    />
                    <button
                      name="incomeproof"
                      onClick={() => incomeproofInputField.current.click()}
                      className=" text-white text-xs opacity-75"
                    >
                      {/* <span className="text-white test-xs">
                        {fileName ? `${fileName}` : "Upload Financial Proof"}
                      </span> */}
                      <span>Upload Financial Proof</span>
                    </button>
                    <input
                      type="file"
                      onChange={handleIncomeChange}
                      ref={incomeproofInputField}
                      className=" hidden "
                      accept=".pdf"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* end of third part---------------------------------- */}

          {/* end of fourth part---------------------------------------- */}
          <div className="">
            <div className="price-list w-[40%] pt-6">
              <div className="flex justify-between border-b-2 border-blue mb-4">
                <p className="text-black font-bold mb-0">Selected Plan</p>
                <p className="text-blue font-bold mb-0">{plan}</p>
              </div>
              <div className="flex justify-between">
                <p className="mb-4 font-bold text-black">Amount Payable</p>
                <p className="font-bold text-blue">{+brokerageAmount}</p>
              </div>
              <div className="flex justify-between">
                <p className="mb-4 font-bold text-black">GST (18%)</p>
                <p className="font-bold text-blue">
                  {(+brokerageAmount * 18) / 100}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="mb-4 font-bold text-black">Partner fee (2%)</p>
                <p className="font-bold text-blue">
                  {Boolean(+brokerageAmount) ? 23.6 : 0.0}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="font-bold mb-4  text-black">Total</p>
                <p className="text-blue font-bold">
                  {Boolean(+brokerageAmount)
                    ? +brokerageAmount + 180 + 23.6
                    : 0.0}
                </p>
              </div>
            </div>
            {error && (
              <p className="self-center text-white text-wrap mb-2 text-lg">
                {error}
              </p>
            )}
            <div className="w-[10%]">
              <div className="">
                <button
                  className="h-[44px] w-[153px] bg-navy text-white font-bold rounded-lg"
                  onClick={handleProceed}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex justify-center items-center">
            <div className="modal-body relative bg-grey rounded-lg my-2 ">
              <div className="mx-4 flex">
                <div className="flex">
                  <img
                    width="150px"
                    className="sm:w-[100px]"
                    src={Zero}
                    alt=""
                  />
                  <div className="flex justify-center items-center flex-col">
                    <p className="md:text-2xl text-white sm:text-lg">
                      is Possible with{" "}
                      <b className="md:text-4xl sm:text-2xl">
                        <span className="text-green">Ni</span>
                        <span className="text-yellow">rm</span>
                        <span className="text-blue">an</span>
                      </b>
                      <br /> brokerage Plan
                    </p>
                  </div>
                </div>
                <img
                  className="cross-image absolute right-[1%] top-[1%] p-1 rounded-full hover:cursor-pointer"
                  onClick={handleClose}
                  width="25px"
                  src={Cross}
                  alt=""
                />
              </div>
              <div className="brokerage-cards flex mx-4 justify-evenly mb-7 max-[409px]:justify-between">
                <div className="w-[45%] border-2 border-white rounded-xl p-3">
                  <div
                    className={`rounded-t-xl flex justify-between py-1 ${
                      Boolean(+brokerageAmount)
                        ? "bg-priceBgYellow"
                        : "bg-transparent"
                    } px-3`}
                  >
                    <p className="font-bold md:text-2xl text-white sm:text-lg">
                      Plan
                    </p>
                    <p className="font-bold md:text-2xl text-white sm:text-lg">
                      A
                    </p>
                  </div>
                  <div className="bg-white h-0.5 opacity-60"></div>
                  <div className="text-white">
                    <p className="md:text-sm mt-2 sm:text-xs">
                      Zero Brokerage Account Opening only with
                    </p>
                    <p className="md:text-xl font-bold text-priceBgYellow sm:text-sm">
                      {Brokerage.zero}
                    </p>
                  </div>

                  <div className="text-white">
                    <div className="flex justify-between ">
                      <p className="md:text-sm  sm:text-xs">Delivery</p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm  sm:text-xs">
                        Mutual Fund
                      </p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm  sm:text-xs">IPO</p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm  sm:text-xs">Intraday</p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm  sm:text-xs">
                        Commodities
                      </p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm  sm:text-xs">Currencies</p>
                      <p className="md:text-sm  sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">F&O</p>
                      <p className="md:text-sm sm:text-xs">0</p>
                    </div>
                    <div className="bg-white h-0.5 opacity-60 my-4"></div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={bill}
                      className={`text-center px-4 py-2 font-semibold rounded-full ${
                        Boolean(+brokerageAmount)
                          ? "bg-priceBgYellow"
                          : "bg-white"
                      } sm:text-xs`}
                    >
                      SELECT
                    </button>
                  </div>
                </div>
                <div className="w-[45%] border-2 border-white rounded-xl p-3">
                  <div className="bg-grey flex justify-between py-1">
                    <div
                      className={`rounded-t-xl flex w-full justify-between py-1 ${
                        !Boolean(+brokerageAmount)
                          ? "bg-priceBgYellow"
                          : "bg-transparent"
                      } px-3`}
                    >
                      <p className="font-bold md:text-2xl text-white sm:text-lg ">
                        Plan
                      </p>
                      <p className="font-bold md:text-2xl text-white sm:text-lg ">
                        B
                      </p>
                    </div>
                  </div>
                  <div className="bg-white h-0.5 opacity-60"></div>
                  <div className="text-white">
                    <p className="md:text-sm mt-2  sm:text-xs">
                      Free Delivery Account Opening only with
                    </p>
                    <p className="md:text-xl font-bold text-white sm:text-sm">
                      {Brokerage.free}
                    </p>
                  </div>
                  <div className="text-white">
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">Brokerage</p>
                      <p className="text-red md:text-xs">13 / trade</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">Delivery</p>
                      <p className="md:text-sm sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className=" md:text-sm sm:text-xs">IPO</p>
                      <p className="md:text-sm sm:text-xs">0</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">Intraday</p>
                      <p className="md:text-sm sm:text-xs">13</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">Commodities</p>
                      <p className="md:text-sm sm:text-xs">13</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">Currencies</p>
                      <p className="md:text-sm sm:text-xs">13</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-sm sm:text-xs">F&O</p>
                      <p className="md:text-sm sm:text-xs">13</p>
                    </div>
                    <div className="bg-white h-0.5 opacity-60 my-4"></div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={bill2}
                      className={`px-4 py-2 text-center font-bold rounded-full ${
                        !Boolean(+brokerageAmount)
                          ? "bg-priceBgYellow"
                          : "bg-white"
                      } max-[587px]:text-xs`}
                    >
                      SELECT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export { Segment };
