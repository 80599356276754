import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // Note: TextField is imported but not used in your code
import Tick from "../../assets/succcess.png";
import "./style.css"

export default function TradingExp(props) {
  const handleChange = (event) => {
    let value = parseInt(event.target.value, 10);

    // Check if the value is less than 0, set it to 0 if true
    if (value < 0) {
      value = 0; // Resets the input to 0 if the user tries to input a value less than 0
    }

    // Call the original handleChange with either the corrected value or the original one
    props.handleChange(event.target.name, value.toString());
  };

  return (
    <div className="mother-input">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <div className="flex justify-between mt-4">
            <p className="mb-3 text-white">Trading Experience</p>
            <div>
              <img src={Tick} alt="" width="30px" height="10px" />
            </div>
          </div>
          <input
            type="number"
            onChange={handleChange}
            onWheel={(e) => e.target.blur()}
            name="tradingExperience"
            placeholder="in Years"
            className="border-2 p-4 w-56 rounded text-black"
            min="0" 
          />
        </FormControl>
      </Box>
    </div>
  );
}
