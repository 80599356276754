import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
function UploadBankDetails(props) {
  const [formData, setFormData] = useState({
    bankValidationProof: "",
  });
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

console.log("error props", props.error);

const getBase64 = (file, name) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    // Extract the base64 data
    let base64Data = reader.result;

    // Check if the base64 string contains the 'data:application/pdf;base64,' prefix
    const base64Prefix = 'data:application/pdf;base64,';
    if (base64Data.startsWith(base64Prefix)) {
      // Remove the prefix from the base64 data
      base64Data = base64Data.substring(base64Prefix.length);
    }

    // Update the formData state with the modified base64 string
    setFormData((prev) => ({ ...prev, [name]: base64Data }));
  };
};


  const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center bg-slate-400 p-8">
        {/* <p className="text-white  rounded-lg p-3 bg-redNeutral">Your bank is not verified . Please upload the details</p> */}
    
          <p className="text-white rounded-lg p-3 bg-redNeutral">
            {props?.error?.message ??"Your bank is not verified. Please upload the details"}
          </p>

        <h1 className="p-4 mb-4">Upload Bank Details</h1>
        <span className="text-rose-800">
          Your bank details are mismatched with your pan card. Please upload
          supporting documents (cancelled check or bank statement or bank
          passbook)
        </span>

        <div className="flex flex-col gap-1 mb-4">
          <div className="flex flex-col justify-center items-center">
          <p className="mb-4 font-bold flex flex-wrap">
                Upload bank passbook or cancel check or bank statement
              </p>
            <p className="text-red">
            (file size should be less than 1mb in <b>JPG/ PNG/ PDF</b> )
      </p>
            <input
              type="file"
              name="bankValidationProof"
              onChange={onChange}
              // accept=".pdf"
            />
            {formData.bankValidationProof && (
              <>
                <div className="w-1/4 h-50 p-4">
                  <img
                    className="w-full"
                    src={formData.bankValidationProof}
                    alt="bank validation proof"
                  />
                </div>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => cancelImage("bankValidationProof")}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
          <div className="self-end">
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                props.handlePostRequest(
                  formData,
                  "details/upload/bank-details",
                  true
                )
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export { UploadBankDetails };
