import React from "react";

const BankDetails = ({ data }) => {
  return (
    <>
      <div className="overflow-y-auto h-[80vh]">
        <div className="flex  justify-between">
          <p className="text-start font-bold fs-5">Equity Bank Details</p>
          <p className=" font-bold">Action</p>
        </div>
        <div className="text-start">
          <label className="font-bold">Name as per Bank</label> <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            value={data?.bankDetails?.bankRegisteredName}
          />
          <br />
          <br />
          <label className="font-bold">Bank Name</label> <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="bankNameInput"
            value={data?.bankDetails?.bankName}
          />
          <br />
          <br />
          <label className="font-bold">IFSC</label> <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="ifscInput"
            value={data?.bankDetails?.ifsc}
          />
          <br />
          <br />
          <label className="font-bold">Bank Account Number</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="accountNumberInput"
            value={data?.bankDetails?.accountNumber}
          />
          <br />
          <br />
          <label className="font-bold">Branch</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="branchInput"
            value={data?.bankDetails?.branch ?? ""}
          />
          <br />
          <br />
          <label className="font-bold">Bank Address</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="bankAddressInput"
            value={data?.bankDetails?.address}
          />
          <br />
          <br />
          <label className="font-bold">MICR Code</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="micrCodeInput"
            value={data?.bankDetails?.micr}
          />
          <br />
          <br />
          <label className="font-bold">Payout id of payment</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="pennyDropInput"
            value={data?.bankDetails?.payoutId}
          />
          <br />
          <br />
          <label className="font-bold">Bank Verification Status</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="pennyReasonInput"
            value={data?.bankDetails?.status ?? ""}
          />
          <br />
          <br />
          <label className="font-bold">Penny Drop Reason</label>
          <br />
          <input
            type="text"
            disabled
            className="rounded-xl py-2 w-full p-2 bg-white "
            id="pennyReasonInput"
            value={data?.bankDetails?.pennyDropReason ?? ""}
          />
          {/* <div className="next-btn px-3">
          <button type="button" className="btn border border-2 border-dark px-3">
            Next
          </button>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default BankDetails;
