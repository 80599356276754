import React from "react";
import { Navbar } from "../../layout/navbar";
import Verification from "../../layout/Verification";
import ClientListing from "../clients";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import RmLogIn from "../../../rmLogin";


const Home = () => {

  
  return (
    <div className="bg-lightGrey">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="*" element={<ClientListing />} />
          <Route path="/client/:clientId" element={<Verification />} />
          <Route path="/open-account" element={<RmLogIn />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Home;
