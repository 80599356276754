import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CameraCapture from "../../components/captureImage";
import Modal from "@mui/material/Modal";
import SignatureCanvas from "react-signature-canvas";
import CameraInstruction from "../../assets/camera/cameraInstruction-1.png";
import RButton from "../../components/rButton";
import useApi from "../../hooks/useApi"
import "./styles.scss";

function Reject(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState(null);
  const { loading, error, postData, fetchData } = useApi();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [imageCapture, setImageCapture] = useState(false);
  const [digiSign, setDigiSign] = useState();
  const [url, setUrl] = useState();
  const [errorLocation, setErrorLocation] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [formData, setFormData] = useState({
    image: "",
    signature: "",
    geolocation: { latitude: "", longitude: "" },
  });

  
  // const [capturingImage, setCapturingImage] = useState(false);
  const validateSignatureFile = (file) => {
    if (!file) return true; // No file selected, no validation needed.

    const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedFileTypes.includes(file.type)) {
      setSignatureError("*Please select a PNG or JPG file for the signature.");
      return false;
    }

    setSignatureError(""); // Clear any previous error messages.
    return true;
  };
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];

    if (validateSignatureFile(file)) {
      getBase64(file, e.target.name);
    }
  };

  const getLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setFormData((prev) => ({
            ...prev,
            geolocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          }));
        },
        (error) => {
          setErrorLocation(
            "* Please provide location permission as its necessary for KYC"
          );
        }
      );
    }
  };

  const getRejectedDocList = async () => {
    const data = await fetchData("rejected/user/list")
    console.log(data)
  } 


  useEffect(() => {
    getRejectedDocList()
    getLocation();
  }, []);

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData((prev) => ({ ...prev, [name]: reader.result }));
    };
  };

  const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCaptureImage = async (val) => {
    if (val && typeof val === "string" && val.startsWith("data:image/")) {
      const compressedImage = await compressImage(val);

      setFormData((prev) => ({ ...prev, image: compressedImage }));
      // Valid image, update the form data and reset capturingImage
      // setFormData((prev) => ({ ...prev, image: val }));------------------------------------------
      // setCapturingImage(false);
    } else {
      // Invalid image or camera issue, clear the image in the form data
      setFormData((prev) => ({ ...prev, image: "" }));
      // setCapturingImage(false);
      alert("Please capture a valid image or ensure your camera is working.");
    }
  };
  const compressImage = (imageDataUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the compressed image to a data URL
        const compressedImage = canvas.toDataURL("image/jpeg");

        resolve(compressedImage);
      };
    });
  };
  // console.log("====0000=====", formData);
  const handleClear = () => {
    digiSign.clear();
    setUrl("");
  };
  const handleGenerate = () => {
    setOpen(false);
    setUrl(digiSign.getTrimmedCanvas().toDataURL("image/png"));
    setFormData((prev) => ({
      ...prev,
      signature: digiSign.getTrimmedCanvas().toDataURL("image/png"),
    }));
  };

  return (
    <div className="bg-red rounded-t-2xl md:p-16 sm:p-8">
        {/**Bank deatils */}
        <div className="flex flex-col justify-center items-center bg-slate-400 md:p-8">
      <h1 className="p-4 mb-4 text-white text-xl md:text-3xl">Upload Bank Details</h1>
      <span className="text-white">
        Your bank details are mismatched with your pan card. Please upload
        supporting documents (cancelled check or bank statement or bank
        passbook)
      </span>

      <div className="flex flex-col gap-1 mb-4">
        <div className="flex flex-col justify-center items-center">
          <span className="mb-4 font-bold text-white">
            Upload bank passbook or cancel check or bank statement
          </span>
          <input type="file" name="bankValidationProof" onChange={onChange} />
          {formData.bankValidationProof && (
            <>
              <div className="w-1/4 h-50 p-4">
                <img
                  className="w-full"
                  src={formData.bankValidationProof}
                  alt="bank validation proof"
                />
              </div>
              <Button
                variant="outlined"
                color="error"
                onClick={() => cancelImage("bankValidationProof")}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
        <div className="self-center py-10">
          <Button
            variant="contained"
            sx={{backgroundColor:"#071945"}}
              onClick={() =>
              props.handlePostRequest(formData, "details/upload/bank-details", true)
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
    {/** */}
      {errorLocation && (
        <div className="text-white text-center">{errorLocation}</div>
      )}
      <div className="flex justify-between flex-wrap">
        <div className="">
          <p className="md:text-xl text-white lg:text-3xl mb-2">Upload Image</p>
          <p className="md:text-xl text-white lg:text-3xl mb-3">& Signature</p>
          <p className="md:text-lg text-white opacity-60 lg:text-2xl my-4">
            Important Image Instructions
          </p>
          <ul className="sm:text-xs md:text-sm text-white ml-4 list-disc">
            <li>Do not wear goggles,cap or anything covering the face</li>
            <li>Capture clear image with a sufficient light</li>
            <li>Not other person should appear in the image</li>
            <li>
              Distance between the camera should not be more than 0.5 meter
            </li>
            <li>Background of the image should be plan, preferable white</li>
            <li>Do not use the rotate image.Capture only an up-right image</li>
          </ul>
        </div>
        <div className="photo-div">
            <img className="w-full h-[25vh]" src={CameraInstruction} alt="" />
          <div className="mt-8">
            {!formData.image && (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   onClick={() => setImageCapture(true)}
              // >
              //   Enable Camera
              // </Button>
              <RButton
                buttonName="Enable Camera"
                handleButtonClick={() => setImageCapture(true)}
                bgColor=""
                color
                border
                text
                externalClassName="bg-white"
              />
            )}
            {formData.image && (
              <>
                <div>
                  <img
                    className="border-2 w-[250px] h-[200] rounded-lg"
                    src={formData.image}
                    alt=""
                  />
                </div>
                <div className="mt-2">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => cancelImage("image")}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white h-0.5 w-100 my-8 opacity-40"></div>
      {/* second session */}
      <div className="flex justify-between flex-wrap">
        <div>
          <p className="text-xl text-white">
            Make sure your Signature is clearly visible
            <br />
            <span className="digitalSign">(Choose any one option)</span>
          </p>
          <p className="text-white my-6 me-4">
            Ensure that you upload a clear image of your Signature.
            <br />
            (file sixe should be less than 50kb)
          </p>
        </div>
        <div className="flex flex-col">
          <input
            className="pb-2 w-[75%]"
            type="file"
            name="signature"
            onChange={onChange}
          />
          {signatureError && <p className="text-white">{signatureError}</p>}
          {!url && formData.signature && (
            <>
              <div className="border-2 w-[250px] h-[200] rounded-lg p-2">
                <img className="" src={formData.signature} alt="signature" />
              </div>
              <div className="mt-2">
                {" "}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => cancelImage("signature")}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
              {/* <p className="text-white font-bold text-2xl my-2">OR</p> */}
          </div>
      </div>
      <div className="digitalSign">
        <div className="font-bold text-2xl my-8 text-center text-white">OR</div>
        <div className="flex justify-around flex-wrap">
          <div>
            <button
              className="bg-white font-bold rounded-lg p-2"
              onClick={handleOpen}
            >
              Digital Signature
            </button>
          </div>
          <div>
            <img
              className="border-2 border-black mt-5 rounded-lg w-[250px] h-[200px]"
              src={url}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="bg-grey h-0.5 w-100 my-3"></div>
      <div>
        <Modal open={imageCapture} onClose={() => setImageCapture(false)}>
          <div className="flex justify-start">
            <CameraCapture
              // sx={{display: "flex"}}
              handleCaptureImage={handleCaptureImage}
              imageValue={formData.image}
              onSave={() => setImageCapture(false)}
            />
          </div>
        </Modal>
      </div>
      {formData.image && formData.signature ? (
        <Button
          disabled={Boolean(errorLocation)}
          // variant="contained"
          // color="success"
          sx={{backgroundColor:"#071945"}}
          onClick={() =>
            props.handlePostRequest(formData, "details/photo-signature")
          }
        >
          Submit
        </Button>
      ) : (
        formData.image &&
        formData.digiSign && (
          <Button
            disabled={Boolean(errorLocation)}
            variant="contained"
            color="success"
            onClick={() =>
              props.handlePostRequest(formData, "details/photo-signature")
            }
          >
            Submit
          </Button>
          
        )
      )}
      <Modal open={open} onClose={handleClose}>
        <div className="flex justify-around flex-wrap">
          <div>
            <SignatureCanvas
              canvasProps={{
                width: 300,
                height: 250,
                className: "sigCanvas border-2 border-grey rounded-lg bg-white",
              }}
              ref={(data) => setDigiSign(data)}
            />
            <Button
              variant="contained"
              color="error"
              onClick={handleClear}
              sx={{ m: 4 }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}



export { Reject };
