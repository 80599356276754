import { React, useState } from "react";
import { useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";
import PdfPreview from "../../components/pdfViewer";

const PanInfo = ({ data,updateRejectData }) => {
  const { loading, error, postData, fetchData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");

  // const handleApiCall = async () => {
  //   if (remark?.length > 10) {
  //     try {
  //       const res = await postData(
  //         `reject/upload/${data?.userDetail?.phoneNumber}`,
  //         {
  //           PAN_CARD: remark,
  //         }
  //       );
  //       toast.success("Rejected successfully");
  //     } catch (err) {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };
  const handleConsole =(e)=>{
    e.preventDefault()
    const newRem = remark ==="" ? "" : `<b>PAN</b>${remark}`
    updateRejectData({ PAN_CARD: newRem });
  }

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientSignature: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  useEffect(() => {
    const storedRemark = localStorage.getItem("Pan");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("Pan", remark);
  }, [remark]);
 

  return (
    <>
      <div>
        <Loader open={loading} />
        <ToastContainer />
        <div className="head-container">
          <div className="flex items-center">
        </div>
        </div>
        <div className="border-2 border-cream">
        {data?.nomineeDetails[0]?.proofOfAddress ? (
          <PdfPreview base64String={data?.nomineeDetails[0]?.proofOfAddress ?? ""} />
        ) : (
          <p className="text-center mt-10">Invalid Nominee format or pdf was not uploaded by the user</p>
        )}</div>
      </div>
    </>
  );
};

export { PanInfo };
