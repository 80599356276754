import { useState, useEffect } from "react";
import { UploadPhotoAndSignature } from "./uploadPhotoAndSignature";
import { Segment } from "./segment";
import { PersonalDetails } from "./personalDetails";
import { Login } from "./login";
import { DigiLocker } from "./digiLocker";
import { BankAccount } from "./bankAccount";
import { MobileLogin } from "./mobileLogin";
import { Verification } from "./verification";
import { DownloadPDF } from "./downloadPDF";
import { UploadBankDetails } from "./uploadBankDetails";
import { UploadPanProof } from "./panProof";
import { Legality } from "./legality";
import { Reject } from "./reject";
import { ThankYou } from "./thankYou";
import { Payment } from "./payment";
import { ClientIdLogin } from "./ClientIdLogin";
import useApi from "../hooks/useApi";
import Loader from "../components/loader";
import useSessionStorage from "../hooks/useSession";
import RYZLOGO from "../assets/ryzmainlogo.png";
import Ekyc from "../assets/kyc/ekycLogo.svg";
import { Thanks } from "./thanksPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserLogin from "../RmAdmin/userLogin"
import Super_Home from "../SuperAdmin/views/home";

function KYC() {
  const [response, setResponse] = useState("");
  const { loading, postData, error, fetchData } = useApi();
  const [legalityData, setLegalityData] = useState();
  const [kycStatus, setKycStatus] = useSessionStorage("kycStatus", "");
  const [kycStage, setKycStage] = useSessionStorage("kycStage", "");
  const handlePostRequest = async (data, url, isPatch = false) => {
    console.log(url,"-------------------url")
    try {
      const response = await postData(url, data, isPatch);
      console.log(response,"----------------------------response")
      const resKycStatus = await response?.kycStatus;
      (await resKycStatus) && setKycStatus(resKycStatus);
      setResponse(response);
    } catch (e) {
      const err = await e;
      toast.error(err.message ?? "Something went wrong");
    }
  };
  
  const isToken = () => {
    const token = sessionStorage.getItem("token");
    if (token?.length > 15) return true;
    return false;
  };
  const handleFetchData = async (url) => {
    try {
      const response = await fetchData(url);
      const resKycStatus = await response?.kycStatus;
      (await resKycStatus) && setKycStatus(resKycStatus);
      setResponse(response);
    } catch (e) {
      const err = await e;
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    const pathName = window?.location?.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const documnetIds = urlParams.get("documentId");
    if (pathName === "/legality/callback" && documnetIds && isToken()) {
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/legality/callback", "");
      window.history.replaceState({}, document.title, newURL);
      handleFetchData(`esign/esign/${documnetIds}`);
      setLegalityData(documnetIds);
    } else if (pathName === "/rm-login") {
      setKycStage("RM_LOGIN");
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/rm-login", "");
      window.history.replaceState({}, document.title, newURL);
    }else if (pathName === "/super-admin") {
      setKycStage("SUPER_ADMIN");
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/super-admin", "");
      window.history.replaceState({}, document.title, newURL);
    }
  }, []);

  const handleKycStatus = (val) => {
    setKycStatus(val);
  };

  const renderKycComponent = () => {
    if (isToken()) {
      switch (kycStatus) {
        case "DOWNLOAD_PDF":
          return <DownloadPDF />;
        case "UPLOAD_PHOTO":
          return (
            <UploadPhotoAndSignature handlePostRequest={handlePostRequest} />
          );
        case "SEGMENT":
          return <Segment handleKycStatus={handleKycStatus} handlePostRequest={handlePostRequest} />;
        case "PAYMENT":
          return <Segment handleKycStatus={handleKycStatus} handlePostRequest={handlePostRequest} />;
        case "PERSONAL_INFO":
          return <PersonalDetails handlePostRequest={handlePostRequest} />;
        case "LOGIN":
          return <Login handlePostRequest={handlePostRequest} />;
        case "DIGILOCKER":
          return <DigiLocker handlePostRequest={handlePostRequest} />;
        case "VERIFICATION":
          return (
            <Verification handleKycStatus={handleKycStatus} data={response} />
          );
        case "BANK_PROOF_UPLOAD":
          return (
            <UploadBankDetails
              error={response}
              handlePostRequest={handlePostRequest}
            />
          );
        case "BANK_DETAILS":
          return <BankAccount handlePostRequest={handlePostRequest} />;
        case "PAN_PROOF_REQUIRED":
          return <UploadPanProof handlePostRequest={handlePostRequest} />;
        case "THANK_YOU":
          return <Thanks />;
        case "E_SIGN":
          return <Legality data={response} docId={legalityData} />;
        case "REJECTED":
          return (
            <Reject data={response} handlePostRequest={handlePostRequest} />
          );
        case "THANK_YOU":
          return <ThankYou />;
        case "USER_PROCESS_COMPLETED":
          return <ThankYou />;
        case "PAYMENT":
          return <Payment />;
        default:
          return (
            <MobileLogin
              legalityData={legalityData}
              handlePostRequest={handlePostRequest}
            />
          );
      }
    } else if (kycStage === "RM_LOGIN") {
      return (
       <UserLogin/>
      );
    } else if (kycStage === "SUPER_ADMIN") {
      return (
        <Super_Home/>
      );
    }
    else {
      return (
        <MobileLogin
          handlePostRequest={handlePostRequest}
          legalityData={legalityData}
        />
      );
    }
  };

  return (
    <main className="">
      <ToastContainer />
      <Loader open={loading} />
      <section className="text-black md:mx-6 h-screen">
        <div className="p-3 md:py-5 md:px-12">
          <img className="w-[100px]" src={RYZLOGO} alt="" />
        </div>
        {renderKycComponent()}
      </section>
    </main>
  );
}

export default KYC;
